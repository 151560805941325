import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import get from 'lodash/get';

import ProgressBar from '../ProgressBar/ProgressBar';
import css from '../index.css';
import {InspectInteriorContentText} from './constant'
import UploadSection from '../UploadSection'
import { nextDropStep, nextPickStep } from '../ProgressBar/ProgressBar.duck';
import IconSpinner from '../../IconSpinner/IconSpinner';
import LOCK from '../../../assets/newPickupAndDropoff/lock-car.svg';
import DLGO from '../../../assets/newPickupAndDropoff/dlgo-car.svg';
import { getLockboxCode, unlockShuCar } from '../../../util/notification';
import callhost from '../../../assets/newPickupAndDropoff/callhost.svg';
import FuelGauge from '../../../components/PickAndDropPanel/Components/FuelGuageMeter/index';
import UploadIcon from '../Components/Icons/uploadIcon';
import { CANCEL_BOOKING_BUTTON_ID } from '../../../util/gtm/gtmConstants';
import { ModalHelp } from '../../../components';
import CodeGen from '../Components/LockBoxCode';
import AlertBox from '../../AlertBox/AlertBox';
import { Button } from '../../../components';
const InspectInteriorComponent = ({
  isPickUp,
  currentUser,
  intl,
  isCustomer,
  onNoteChange,
  isDrivelahGo,
  lockbox,
  transaction,
  setFuelGaugePhotos,
  setActivePhotoSection,
  fuelGaugePhotos,
  setFuelReceiptPhotos,
}) => {
  const dispatch = useDispatch();
  const [isUploaded, setIsUploaded] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showFuelSectionFirst, setShowFuelSectionFirst] = useState(false);
  const [isHostCall, setIsHostCall] = useState(true);
  const [fuelRecieptSection, setFuelRecieptSection] = useState(false);
  const [nettFuel, setNettFuel] = useState(0);
  const [lastImageURl, setLastImageUrl] = useState('');
  const [showCancelHelpModal, setShowCancelHelpModal] = useState(false);
  const [isExpectedLength, setIsExpectedLength] = useState(0);
  const [lockBoxDigits, setLockBoxDigits] = useState(null);
  const [alert, setAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState();

  const verificationStatus = useSelector(state => state.ProgressBarReducer.verificationStatus);
  const uploadSectionRef = useRef(null);

  const getHostPhoneNumber = transaction => {
    if(transaction && transaction.customer &&
      transaction.customer.attributes  &&
      transaction.customer.attributes.profile &&
      transaction.customer.attributes.profile.publicData &&
      transaction.customer.attributes.profile.publicData.guestIdentityVerificationStatus === "confirmed" ) {
        return get(transaction, 'attributes.protectedData.hostPhoneNumber', null);
      }
    return null;
  };

  const hostPhoneNumber = getHostPhoneNumber(transaction);

  useEffect(() => {
    if (fuelGaugePhotos && fuelGaugePhotos.length) {
      const updatedPhotos = fuelGaugePhotos.map(photo => ({
        ...photo,
        note: nettFuel,
      }));
      setFuelGaugePhotos(updatedPhotos);
    }
  }, [nettFuel]);

  //functions
  const getTextContent = (isUploaded, showFuelSectionFirst) => {
    if (isUploaded) {
      return InspectInteriorContentText.FuelGaugeLevelView;
    }

    if (showFuelSectionFirst) {
      return InspectInteriorContentText.FuelGaugeLevelViewPhotos;
    }

    const exteriorView = { ...InspectInteriorContentText.InspectExteriorView };

    exteriorView.img = isDrivelahGo ? DLGO : lockbox ? LOCK : exteriorView.img;
    exteriorView.message = isDrivelahGo && !lockbox ?  'The car has been unlocked. You are just a few steps away from embarking on your trip.': lockbox ? "The car keys are kept inside the Lockbox. The lockbox is attached on the front driver window. Please follow these steps:" : exteriorView.message
    exteriorView.callText = isDrivelahGo && !lockbox
    ? 'Car not unlocked? Call us'
    : lockbox
    ?  "Facing issues opening the lockbox?"
    : exteriorView.callText;

    return exteriorView;
  };

  const handleAddMorePhotos = () => {
    if (uploadSectionRef.current) {
      uploadSectionRef.current.handleClickUpload();
    }
  };

  const apiData = {
    user_id: transaction.customer.attributes.profile.publicData.shuUserId,
    booking_id: transaction.id.uuid,
    fleet_id: transaction.listing.attributes.publicData.license_plate_number,
  };

  const ActionButton = () => (
    <div className={`${css.warningDesktopWrapper} ${css.addMoreButtonWrepper} ${css.interiorLink}`}>
      <button
        className={css.addMoreButton}
        onClick={
          currentTextContent.callText === 'Car not unlocked? Call us' ||
          currentTextContent.callText === 'Facing issues opening the lockbox?'
            ? () => setShowCancelHelpModal(true)
            : () => setIsHostCall(false)
        }
      >
        {currentTextContent.callText}
      </button>
    </div>
  );

  // variables
  const currentTextContent = getTextContent(isUploaded, showFuelSectionFirst);
  const isVerificationInProgress =
    currentTextContent.buttonText === 'Continue to next step' &&
    verificationStatus === 'inProgress';
  const isLockboxCondition =
    lockbox && isHostCall && !isDrivelahGo && currentTextContent.title !== 'Fuel gauge level';
  const shouldShowFuelGauge = !fuelRecieptSection && isExpectedLength && isUploaded;
  const shouldShowFuelReceipt = !isPickUp && fuelRecieptSection && isExpectedLength;

  const isDisabled = loading || isVerificationInProgress || (isLockboxCondition && loading) || (shouldShowFuelGauge && !Boolean(nettFuel-0.6 > 0));

  const listingId = transaction.listing.id.uuid;
  const handleNextStep = () => {
    switch (currentTextContent.buttonText) {
      case 'Upload Photos':
        handleAddMorePhotos();
        if (lockbox) {
          setIsHostCall(true);
        }
        break;
      case 'Continue to interior inspection':
        if (isDrivelahGo) {
          unlockShuCar(apiData);
        }
        setShowFuelSectionFirst(true);
        break;
      case 'Continue to next step':
        if (!isPickUp && !fuelRecieptSection) {
          setFuelRecieptSection(true);
        } else {
          isPickUp ? dispatch(nextPickStep()) : dispatch(nextDropStep());
        }
        break;
      default:
        console.warn('Unhandled button text:', currentTextContent.buttonText);
    }
  };

  // const handleFileInputClick = () => {
  //   document.getElementById('fileInput').click();
  // };

  const handleFileChange = event => {
    const file = event.target.files[0];
    if (file) {
      console.log('File selected:', file.name);
      // Add your file handling logic here
    }
  };

  useEffect(() => {
    if (!isPickUp && !isExpectedLength) {
      setShowFuelSectionFirst(true);
      setFuelRecieptSection(false)
      setNettFuel(0);
    }
  }, [isExpectedLength]);

  useEffect(() => {
    const getCode = async ()=> {
      await generateLockBoxCode(listingId);
    }
    if (lockbox && isPickUp) {
      getCode();
    }
  }, []) 

   const generateLockBoxCode = async (listingId, force)=> {
    try {
      setLoading(true);
      const data = await getLockboxCode(listingId, force)
      setLockBoxDigits(data && data.code.split(''));
    } catch (error) {
      console.log(error.response.data.error);
      setAlertMessage(error.response.data.error)
      setAlert(true);
    } finally {
      setLoading(false);
    }
  }

  const regenerateLockBoxCode = (
    <Button
      className={css.retryButton}
      onClick={() => {
        setAlert(false);
        setAlertMessage(null);
        generateLockBoxCode(listingId, true);
      }}
    >
      Generate Again
    </Button>
  );
  // useEffect(() => {
  //   console.log(isExpectedLength);
  // }, [isExpectedLength]);

  return (
    <>
      <div className={`${css.stepContainer} ${css.mb}`}>
        <div className={css.wrapper}>
          <div className={css.contentWrapper}>
            <h1>{currentTextContent.title}</h1>
            <p>{currentTextContent.message}</p>
          </div>
          { alert && (
            <AlertBox
              title="LockBox error"
              message={`${alertMessage}`}
              type="error"
              button={regenerateLockBoxCode}
            />
          )}
          {!isUploaded && !loading && (
            <div className={css.imgWrapper}>
              {lockbox && !showFuelSectionFirst ? <CodeGen codeNumber={lockBoxDigits} isPickup={isPickUp} /> : <img src={currentTextContent.img} alt={currentTextContent.title} />}
            </div>
          )}
          <UploadSection
            ref={uploadSectionRef}
            intl={intl}
            currentUser={currentUser}
            isPickUp={isPickUp}
            isCustomer={isCustomer}
            onNoteChange={onNoteChange}
            setIsUploaded={setIsUploaded}
            setLoading={setLoading}
            setPhotosData={setFuelGaugePhotos}
            type={'fuelGaugePhotos'}
            setActivePhotoSection={setActivePhotoSection}
            id={ isDrivelahGo && isPickUp  ? 4 : 3}
            note={nettFuel}
            setIsExpectedLength={setIsExpectedLength}
          />
        </div>
        {(isDrivelahGo || lockbox) && !isUploaded ? <ActionButton /> : ''}

        {!!shouldShowFuelGauge && (
          <div>
            <FuelGauge setNettFuel={setNettFuel} />
          </div>
        )}
        {!!shouldShowFuelReceipt && (
          <div className={css.fuelRecieptContainer}>
            <div className={css.fuelLevelContainer}>
              <span className={css.label}>Fuel level</span>
              <span className={css.fuelLevel}>{Math.round(nettFuel)}%</span>
            </div>
            <div className={css.receiptContainer}>
              <p className={css.receiptQuestion}>Did you top up fuel during your trip?</p>
              <div className={css.uploadBox} onClick={handleAddMorePhotos}>
                <div className={css.uploadIcon}>
                  <UploadIcon />
                </div>
                <p className={css.uploadText}>
                  {lastImageURl || 'Attach fuel receipt to avail refund'}
                </p>
                <input
                  id="fileInput"
                  type="file"
                  style={{ display: 'none' }}
                  onChange={handleFileChange}
                />
              </div>
            </div>
            <UploadSection
              ref={uploadSectionRef}
              intl={intl}
              currentUser={currentUser}
              isPickUp={isPickUp}
              isCustomer={isCustomer}
              onNoteChange={onNoteChange}
              setLoading={setLoading}
              setIsUploaded={() => setShowFuelSectionFirst(false)}
              setPhotosData={setFuelReceiptPhotos}
              type="fuelReceiptPhotos"
              setActivePhotoSection={setActivePhotoSection}
              setLastImageUrl={setLastImageUrl}
              id={4}
              />
          </div>
        )}
      </div>
      <div className={css.footerContainer}>
        <div className={`${css.footerWrapper} ${css.stepContainer}`}>
          <div className={css.footerProgressBar}>
            <ProgressBar isPickUp={isPickUp} />
          </div>
          <button className={css.footerButton} onClick={handleNextStep} disabled={isDisabled}>
            {loading ? 'Uploading...' : currentTextContent.buttonText}
            <div>
              {loading && (
                <div className={css.uploadLoading}>
                  <IconSpinner />
                </div>
              )}
            </div>
          </button>
        </div>
      </div>
      <ModalHelp
        onClose={() => setShowCancelHelpModal(false)}
        cancelButtonId={CANCEL_BOOKING_BUTTON_ID}
        isOpen={showCancelHelpModal}
      />
    </>
  );
};

export default InspectInteriorComponent;
