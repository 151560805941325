import { ensureTransaction } from './data';

import {
  LINE_ITEM_CUSTOMER_PROMO,
  LINE_ITEM_MASTERCARD_PROMO,
  LINE_ITEM_NEW_CAR_DISCOUNT,
} from './types';

export const PROCESS_NAME_ONE_FOR_ALL = 'one-for-all';
/**
 * Transitions
 *
 * These strings must sync with values defined in Flex API,
 * since transaction objects given by API contain info about last transitions.
 * All the actions in API side happen in transitions,
 * so we need to understand what those strings mean.
 */

// Covid
export const TRANSITION_COVID_REQUEST = 'transition/covid-request';
export const TRANSITION_COVID_CONFIRM_PAYMENT = 'transition/covid-confirm-payment';
export const TRANSITION_DONATION_REQUEST = 'transition/donation-request';
export const TRANSITION_VERIFICATION_REQUEST = 'transition/verification-request';
export const TRANSITION_VERIFICATION_CONFIRM_PAYMENT = 'transition/verification-confirm-payment';
export const TRANSITION_DONATION_CONFIRM_PAYMENT = 'transition/donation-confirm-payment';


export const TRANSITION_ENQUIRE = 'transition/enquire';
export const TRANSITION_REQUEST_PAYMENT_NORMAL_COMMERCIAL =
  'transition/request-payment-normal-commercial';
export const TRANSITION_REQUEST_PAYMENT_NORMAL_PRIVATE =
  'transition/request-payment-normal-private';
export const TRANSITION_REQUEST_PAYMENT_YOUNG_COMMERCIAL =
  'transition/request-payment-young-commercial';
export const TRANSITION_REQUEST_PAYMENT_YOUNG_PRIVATE = 'transition/request-payment-young-private';

export const TRANSITION_EXPIRE_PAYMENT = 'transition/expire-payment';
export const TRANSITION_CONFIRM_PAYMENT = 'transition/confirm-payment';
export const TRANSITION_CONFIRM_PAYMENT_INSTANT = 'transition/confirm-payment-instant';

// Deposit tx transitions
export const TRANSITION_DEPOSIT_REQUEST = 'transition/deposit-request';
export const TRANSITION_DEPOSIT_CONFIRM_PAYMENT = 'transition/confirm-deposit-payment';

export const TRANSITION_DEPOSIT_REFUND_REQUESTED = 'transition/deposit-refund-requested'

export const TRANSITION_EXPIRE = 'transition/expire';
export const TRANSITION_DECLINE = 'transition/decline';
export const TRANSITION_WITHDRAW = 'transition/withdraw';
export const TRANSITION_ADMIN_WITHDRAW = 'transition/withdraw-by-admin';
export const TRANSITION_ACCEPT = 'transition/accept';
export const TRANSITION_ACCEPT_BY_ADMIN = 'transition/accept-by-admin';
export const TRANSITION_UNVERIFIED_DECLINED = 'transition/unverified-declined';
export const TRANSITION_UNVERIFIED_EXPIRE = 'transition/unverified-expire';
export const TRANSITION_UNVERIFIED_ACCEPTED_INSTANT = 'transition/unverified-accepted-instant';
export const TRANSITION_ACCEPTED_PENDING_STATE = 'transition/unverified-accepted-pending-state';
export const TRANSITION_UNVERIFIED_VERIFICATION_SENT = 'transition/unverified-verification-sent';
export const TRANSITION_UNVERIFIED_VERIFICATION_SENT_ADMIN = 'transition/unverified-verification-sent-admin';
export const TRANSITION_UNVERIFIED_ACCEPTED = 'transition/unverified-accepted';
export const TRANSITION_UNVERIFIED_WITHDRAW = 'transition/unverified-withdraw';

export const TRANSITION_PROVIDER_CANCEL_REFUNDABLE = 'transition/provider-cancel-refundable';
export const TRANSITION_CUSTOMER_CANCEL_REFUNDABLE = 'transition/customer-cancel-refundable';
export const TRANSITION_ADMIN_CANCEL_AFTER_ACCEPTED_REFUNDABLE =
  'transition/admin-cancel-after-accepted-refundable';
export const TRANSITION_REQUEST_PICK_UP_AFTER_ACCEPTED =
  'transition/request-pick-up-after-accepted';

export const TRANSTION_TRIP_STARTED_BY_ADMIN_AFTER_ACCEPTED_REFUNDABLE = 'transition/trip-started-by-admin-after-accepted-refundable';
export const TRANSITION_TRIP_STARTED_BY_ADMIN_AFTER_ACCEPTED_NON_REFUNDABLE = 'transition/trip-started-by-admin-after-accepted-non-refundable';

export const TRANSITION_TRIP_COMPLETED_BY_ADMIN_AFTER_ACCEPTED_NON_REFUNDABLE='transition/trip-completed-by-admin-after-accepted-non-refundable'
export const TRANSITION_TRIP_COMPLETED_BY_ADMIN_AFTER_PICK_UP_REQUESTED_NON_REFUNDABLE = 'transition/trip-completed-by-admin-after-pick-up-requested-non-refundable'
export const TRANSITION_TRIP_COMPLETED_BY_ADMIN_AFTER_PICK_UP_CONFIRMED = 'transition/trip-completed-by-admin-after-pick-up-confirmed'
export const TRANSITION_TRIP_COMPLETED_BY_ADMIN_AFTER_DROP_OFF_REQUESTED = 'transition/trip-completed-by-admin-after-drop-off-requested'

export const TRANSITION_MARK_ACCEPTED_AFTER_ONE_HOUR = 'transition/mark-accepted-after-one-hour';

export const TRANSITION_PROVIDER_CANCEL_AFTER_ACCEPTED_ONE_HOUR =
  'transition/provider-cancel-after-accepted-one-hour';
export const TRANSITION_CUSTOMER_CANCEL_AFTER_ACCEPTED_ONE_HOUR =
  'transition/customer-cancel-after-accepted-one-hour';
export const TRANSITION_MARK_ACCEPTED_NON_REFUNDABLE = 'transition/mark-accepted-non-refundable';
export const TRANSITION_ADMIN_CANCEL_AFTER_ACCEPTED_ONE_HOUR =
  'transition/admin-cancel-after-accepted-one-hour';

export const  TRANSITION_REQUEST_PICK_UP_DLGO_AFTER_ACCEPTED = 'transition/request-pick-up-dlgo-after-accepted';

export const TRANSITION_REQUEST_DROP_OFF_DLGO = 'transition/request-drop-off-dlgo';

export const TRANSITION_REQUEST_PICK_UP_DLGO_NON_REFUNDABLE =
  'transition/request-pick-up-dlgo-non-refundable';

export const TRANSITION_REQUEST_PICK_UP_NON_REFUNDABLE =
  'transition/request-pick-up-non-refundable';
export const TRANSITION_CUSTOMER_CANCEL_NON_REFUNDABLE =
  'transition/customer-cancel-non-refundable';
export const TRANSITION_ADMIN_CANCEL_AFTER_ACCEPTED_NON_REFUNDABLE =
  'transition/admin-cancel-after-accepted-non-refundable';
export const TRANSITION_PROVIDER_CANCEL_NON_REFUNDABLE =
  'transition/provider-cancel-non-refundable';
export const TRANSITION_EXPIRE_ACCEPTED_NON_REFUNDABLE =
  'transition/expire-accepted-non-refundable';

// Update booking
// before pick-up

export const TRANSITION_UPDATE_BOOKING_BEFORE_PICK_UP_REFUNDABLE =
  'transition/update-booking-before-pick-up-refundable';

export const TRANSITION_UPDATE_BOOKING_BEFORE_PICK_UP_ONE_HOUR =
  'transition/update-booking-before-pick-up-one-hour';

export const TRANSITION_UPDATE_BOOKING_BEFORE_PICK_UP_NON_REFUNDABLE =
  'transition/update-booking-before-pick-up-non-refundable';

export const TRANSITION_PROVIDER_CANCEL_AFTER_UPDATE_BOOKING =  'transition/provider-cancel-after-update-booking';
export const TRANSITION_CUSTOMER_CANCEL_AFTER_UPDATE_BOOKING =  'transition/customer-cancel-after-update-booking';
export const TRANSITION_ADMIN_CANCEL_AFTER_UPDATE_BOOKING =  'transition/admin-cancel-after-update-booking';

export const TRANSITION_ACCEPT_UPDATE_BOOKING_BEFORE_PICK_UP =
  'transition/accept-update-booking-before-pick-up';

export const TRANSITION_AUTO_ACCEPT_UPDATE_BOOKING_BEFORE_PICK_UP =
  'transition/auto-accept-update-booking-before-pick-up';

export const TRANSITION_CANCEL_UPDATE_BOOKING_BEFORE_PICK_UP =
  'transition/cancel-update-booking-before-pick-up';

export const TRANSITION_EXPIRE_UPDATE_BOOKING_BEFORE_PICK_UP =
  'transition/expire-update-booking-before-pick-up';

export const TRANSITION_RETURN_BACK_AFTER_ACCEPT_UPDATE_BOOKING_BEFORE_PICK_UP =
  'transition/return-back-after-accept-update-booking-before-pick-up';

export const TRANSITION_RETURN_BACK_AFTER_AUTO_ACCEPT_UPDATE_BOOKING_BEFORE_PICK_UP =
  'transition/return-back-after-auto-accept-update-booking-before-pick-up';

export const TRANSITION_RETURN_BACK_AFTER_CANCEL_UPDATE_BOOKING_BEFORE_PICK_UP =
  'transition/return-back-after-cancel-update-booking-before-pick-up';

export const TRANSITION_ADMIN_CANCEL_UPDATE_BOOKING_AFTER_ACCEPTED_NON_REFUNDABLE =
  'transition/admin-cancel-update-booking-after-await-update-booking-before-pick-up';

export const TRANSITION_ADMIN_ACCEPT_UPDATE_BOOKING_AFTER_AWAIT_UPDATE_BOOKING_BEFORE_PICK_UP =
  'transition/admin-accept-update-booking-after-await-update-booking-before-pick-up';

export const TRANSITION_ADMIN_CANCEL_UPDATE_BOOKING_AFTER_AWAIT_UPDATE_BOOKING_BEFORE_PICK_UP =
  'transition/admin-cancel-update-booking-after-accepted-non-refundable';

export const TRANSITION_ADMIN_ACCEPT_UPDATE_BOOKING_BEFORE_DROP_OFF =
  'transition/admin-accept-update-booking-before-drop-off';

export const TRANSITION_ADMIN_CANCEL_UPDATE_BOOKING_BEFORE_DROP_OFF =
  'transition/admin-cancel-update-booking-before-drop-off';

export const TRANSITION_ADMIN_CANCEL_UPDATE_BOOKING_BEFORE_DROP_OFF_AFTER_ACCEPTED =
  'transition/admin-cancel-update-booking-before-drop-off-after-accepted';

export const TRANSITION_ADMIN_CANCEL_UPDATE_BOOKING_AFTER_ACCEPTED_REFUNDABLE =
  'transition/admin-cancel-update-booking-after-accepted-refundable';

export const TRANSITION_RETURN_BACK_AFTER_EXPIRE_UPDATE_BOOKING_BEFORE_PICK_UP =
  'transition/return-back-after-expire-update-booking-before-pick-up';

export const TRANSITION_UPDATE_BOOKING_CHILD_TX_REQUEST = 'transition/update-booking-child-tx-request-payment';
export const TRANSITION_UPDATE_BOOKING_CHILD_TX_CONFIRM_PAYMENT = 'transition/update-booking-child-tx-confirm-payment';
export const TRANSITION_CONFIRM_PICK_UP_AFTER_ACCEPTED = "transition/confirm-pick-up-after-accepted"
export const TRANSITION_CONFIRM_PAYMENT_UNVERIFIED = 'transition/confirm-payment-unverified';
export const TRANSITION_CONFIRM_PAYMENT_INSTANT_UNVERIFIED = 'transition/confirm-payment-instant-unverified';
export const TRANSITION_UNVERIFIED_DECLINED_PENDING_STATE = 'transition/unverified-declined-pending-state';
export const TRANSITION_UNVERIFIED_DECLINED_PENDING_STATE_INSTANT = 'transition/unverified-declined-pending-state-instant';
export const TRANSITION_UNVERIFIED_ACCEPTED_PENDING_STATE_INSTANT = 'transition/unverified-accepted-pending-state-instant';
export const TRANSITION_UNVERIFIED_EXPIRE_INSTANT = 'transition/unverified-expire-instant';
export const TRANSITION_UNVERIFIED_VERIFICATION_SENT_INSTANT  = 'transition/unverified-verification-sent-instant';
export const TRANSITION_UNVERIFIED_VERIFICATION_SENT_INSTANT_ADMIN  = 'transition/unverified-verification-sent-instant-admin';
export const TRANSITION_UNVERIFIED_VERIFICATION_DECLINE = 'transition/unverified-verification-decline';
export const TRANSITION_UNVERIFIED_VERIFICATION_INSTANT_DECLINE = 'transition/unverified-verification-instant-decline';
export const TRANSITION_UNVERIFIED_WITHDRAW_INSTANT = 'transition/unverified-withdraw-instant';
export const TRANSITION_UNVERIFIED_VERIFICATION_EXPIRE = 'transition/unverified-verification-expire';
export const TRANSITION_UNVERIFIED_VERIFICATION_ACCEPTED = 'transition/unverified-verification-accepted';
export const TRANSITION_UNVERIFIED_VERIFICATION_INSTANT_ACCEPTED = 'transition/unverified-verification-instant-accepted';
export const TRANSITION_UNVERIFIED_VERIFICATION_INSTANT_EXPIRE = 'transition/unverified-verification-instant-expire';
export const TRANSITION_UPDATE_BOOKING_CHILD_TX_ACCEPT = 'transition/update-booking-child-tx-accept';
export const TRANSITION_UPDATE_BOOKING_CHILD_TX_DECLINE = 'transition/update-booking-child-tx-decline';
export const TRANSITION_UPDATE_BOOKING_CHILD_TX_DECLINE_BY_ADMIN = 'transition/update-booking-child-tx-decline-by-admin';
export const TRANSITION_UPDATE_BOOKING_CHILD_TX_ACCEPT_BY_ADMIN = 'transition/update-booking-child-tx-accept-by-admin';
export const TRANSITION_UPDATE_BOOKING_CHILD_TX_CUSTOMER_CANCEL = 'transition/update-booking-child-tx-customer-cancel';
export const TRANSITION_UPDATE_BOOKING_CHILD_TX_PROVIDER_CANCEL = 'transition/update-booking-child-tx-provider-cancel';
export const TRANSITION_UPDATE_BOOKING_CHILD_TX_ADMIN_CANCEL = 'transition/update-booking-child-tx-admin-cancel';
export const TRANSITION_TRIP_PICK_UP_CONFIRMED_BY_ADMIN_AFTER_ACCEPTED_REFUNDABLE = 'transition/trip-pick-up-confirmed-by-admin-after-accepted-refundable';
export const TRANSITION_TRIP_PICKUP_CONFIRMED_BY_ADMIN_AFTER_ACCEPTED_NON_REFUNDABLE = 'transition/trip-pickup-confirmed-by-admin-after-accepted-non-refundable';



// Update booking
// before drop-off
export const TRANSITION_UPDATE_BOOKING_BEFORE_DROP_OFF =
  'transition/update-booking-before-drop-off';

export const TRANSITION_ACCEPT_UPDATE_BOOKING_BEFORE_DROP_OFF =
  'transition/accept-update-booking-before-drop-off';

export const TRANSITION_AUTO_ACCEPT_UPDATE_BOOKING_BEFORE_DROP_OFF =
  'transition/auto-accept-update-booking-before-drop-off';

export const TRANSITION_CANCEL_UPDATE_BOOKING_BEFORE_DROP_OFF =
  'transition/cancel-update-booking-before-drop-off';

export const TRANSITION_EXPIRE_UPDATE_BOOKING_BEFORE_DROP_OFF =
  'transition/expire-update-booking-before-drop-off';

export const TRANSITION_RETURN_BACK_AFTER_ACCEPT_UPDATE_BOOKING_BEFORE_DROP_OFF =
  'transition/return-back-after-accept-update-booking-before-drop-off';

export const TRANSITION_RETURN_BACK_AFTER_AUTO_ACCEPT_UPDATE_BOOKING_BEFORE_DROP_OFF =
  'transition/return-back-after-auto-accept-update-booking-before-drop-off';

export const TRANSITION_RETURN_BACK_AFTER_CANCEL_UPDATE_BOOKING_BEFORE_DROP_OFF =
  'transition/return-back-after-cancel-update-booking-before-drop-off';

export const TRANSITION_RETURN_BACK_AFTER_EXPIRE_UPDATE_BOOKING_BEFORE_DROP_OFF =
  'transition/return-back-after-expire-update-booking-before-drop-off';


export const TRANSITION_CUSTOMER_CANCEL_PICK_UP_REQUESTED_NON_REFUNDABLE =
  'transition/customer-cancel-pick-up-requested-non-refundable';
export const TRANSITION_ADMIN_CANCEL_PICK_UP_REQUESTED_NON_REFUNDABLE =
  'transition/admin-cancel-pick-up-requested-non-refundable';
export const TRANSITION_EXPIRE_CONFIRM_PICK_UP =
  'transition/expire-pick-up-requested-non-refundable';
export const TRANSITION_CONFIRM_PICK_UP_NON_REFUNDABLE =
  'transition/confirm-pick-up-non-refundable';
export const TRANSITION_DROPOFF_REQUESTED_AFTER_ADMIN_ACCESS_FOR_SHU =
  'transition/drop-off-requested-after-admin-access-for-shu';
export const TRANSITION_DROP_OFF_CONFIRMED_AFTER_ADMIN_ACCESS_FOR_SHU=
  'transition/drop-off-confirmed-after-admin-access-for-shu'
export const ADMIN_ACCESS_AFTER_ACCEPTED_REFUNDABLE =
  'transition/admin-access-after-accepted-refundable';
export const ADMIN_ACCESS_AFTER_ACCEPTED_AFTER_ONE_HOUR =
  'transition/admin-access-after-accepted-after-one-hour';
export const ADMIN_ACCESS_AFTER_ACCEPTED_AFTER_NON_REFUNDABLE =
  'transition/admin-access-after-accepted-non-refundable';
export const ADMIN_ACCESS_AFTER_ACCEPTED_AFTER_PICK_UP_REQUESTED_NON_REFUNDABLE =
  'transition/admin-access-after-pick-up-requested-non-refundable';
export const TRANSITION_EXPIRE_REQUEST_DROP_OFF = 'transition/expire-pick-up-confirmed';
export const TRANSITION_REQUEST_DROP_OFF = 'transition/request-drop-off';
export const TRANSITION_ADMIN_CANCEL_AFTER_PICK_UP_CONFIRMED =
  'transition/admin-cancel-after-pick-up-confirmed';
export const TRANSITION_ADMIN_CONFIRM_PICK_UP_NON_REFUNDABLE  =
'transition/admin-confirm-pick-up-non-refundable';
export const TRANSITION_EXPIRE_ADMIN_ACCESS_FOR_SHU = 'transition/expire-admin-access-for-shu';

export const TRANSITION_DISPUTE = 'transition/dispute';
export const TRANSITION_CONFIRM_DROP_OFF = 'transition/confirm-drop-off';
export const TRANSITION_AUTO_COMPLETE = 'transition/auto-complete';

export const TRANSITION_REVIEW_1_BY_PROVIDER = 'transition/review-1-by-provider';
export const TRANSITION_REVIEW_2_BY_PROVIDER = 'transition/review-2-by-provider';
export const TRANSITION_REVIEW_1_BY_CUSTOMER = 'transition/review-1-by-customer';
export const TRANSITION_REVIEW_2_BY_CUSTOMER = 'transition/review-2-by-customer';
export const TRANSITION_EXPIRE_CUSTOMER_REVIEW_PERIOD = 'transition/expire-customer-review-period';
export const TRANSITION_EXPIRE_PROVIDER_REVIEW_PERIOD = 'transition/expire-provider-review-period';
export const TRANSITION_EXPIRE_REVIEW_PERIOD = 'transition/expire-review-period';

export const TRANSITION_ADMIN_CANCEL_REFUND = 'transition/admin-cancel-refund';
export const TRANSITION_ADMIN_CANCEL_NON_REFUND = 'transition/admin-cancel-non-refund';
export const TRANSITION_ADMIN_REFUND = 'transition/admin-refund';

export const TRANSITION_ADMIN_CONFIRM_DROP_OFF = 'transition/admin-confirm-drop-off';

// deposit, old transaction
export const TRANSITION_REQUEST = 'transition/request';
export const TRANSITION_DEPOSIT_FAIL = 'transition/deposit-fail';
export const TRANSITION_CONFIRM_DEPOSIT_REQUEST = 'transition/confirm-deposit-payment';
export const TRANSITION_EXPIRE_DEPOSIT_REQUEST = 'transition/expire-deposit-payment';
export const TRANSITION_AUTO_REFUND_INITIATED = 'transition/auto-refund-initiated';
export const TRANSITION_DEPOSIT_ADMIN_REFUND_REQUESTED = 'transition/deposit-admin-refund-requested'
export const TRANSITION_AUTO_REFUNDED  = 'transition/auto-refunded';
export const TRANSITION_DEPOSIT_REFUNDED = 'transition/deposit-refunded';
export const TRANSITION_DEPOSIT_STRIPE_REFUNDED = 'transition/deposit-stripe-refunded';
export const TRANSITION_DEPOSIT_DENIED = 'transition/deposit-denied';
export const TRANSITION_DEPOSIT_ON_HOLD = 'transition/deposit-on-hold';
export const TRANSITION_AUTO_REFUND_INITIATED_ON_HOLD = 'transition/auto-refund-initiated-on-hold';
export const TRANSITION_DEPOSIT_ADMIN_REVERTED = 'transition/deposit-admin-reverted';




//SYSTEM TRANSITION

// When a customer makes a booking to a listing, a transaction is
// created with the initial request-payment transition.
// At this transition a PaymentIntent is created by Marketplace API.
// After this transition, the actual payment must be made on client-side directly to Stripe.
export const TRANSITION_REQUEST_PAYMENT = 'transition/request-payment';

// A customer can also initiate a transaction with an enquiry, and
// then transition that with a request.

// Stripe SDK might need to ask 3D security from customer, in a separate front-end step.
// Therefore we need to make another transition to Marketplace API,
// to tell that the payment is confirmed.

// If the payment is not confirmed in the time limit set in transaction process (by default 15min)
// the transaction will expire automatically.

// When the provider accepts or declines a transaction from the
// SalePage, it is transitioned with the accept or decline transition.

// The backend automatically expire the transaction.

// Admin can also cancel the transition.
export const TRANSITION_CANCEL = 'transition/cancel';

// Reviews are given through transaction transitions. Review 1 can be
// by provider or customer, and review 2 will be the other party of
// the transaction.

// Fake transitions, the transitions aren't existed on transaction flow
export const TRANSITION_FAKE_WAITING_FOR_REFUND = 'transition/fake-waiting-for-refund';

/**
 * Actors
 *
 * There are 4 different actors that might initiate transitions:
 */

// Roles of actors that perform transaction transitions
export const TX_TRANSITION_ACTOR_CUSTOMER = 'customer';
export const TX_TRANSITION_ACTOR_PROVIDER = 'provider';
export const TX_TRANSITION_ACTOR_SYSTEM = 'system';
export const TX_TRANSITION_ACTOR_OPERATOR = 'operator';

export const TX_TRANSITION_ACTORS = [
  TX_TRANSITION_ACTOR_CUSTOMER,
  TX_TRANSITION_ACTOR_PROVIDER,
  TX_TRANSITION_ACTOR_SYSTEM,
  TX_TRANSITION_ACTOR_OPERATOR,
];

/**
 * States
 *
 * These constants are only for making it clear how transitions work together.
 * You should not use these constants outside of this file.
 *
 * Note: these states are not in sync with states used transaction process definitions
 *       in Marketplace API. Only last transitions are passed along transaction object.
 */
const STATE_INITIAL = 'initial';
const STATE_ENQUIRY = 'enquiry';
const STATE_PENDING_PAYMENT = 'pending-payment';
const STATE_PAYMENT_EXPIRED = 'payment-expired';
const STATE_UNVERIFIED_BOOKED = 'unverified-booked';
const STATE_UNVERIFIED_BOOKED_INSTANT = 'unverified-booked-instant';
const STATE_UNVERIFIED_VERIFICATION_SENT = 'unverified-verification';
const STATE_UNVERIFIED_VERIFICATION_SENT_INSTANT = 'unverified-verification-instant'
const STATE_PREAUTHORIZED = 'preauthorized';
const STATE_DECLINED = 'declined';
const STATE_CANCELED = 'canceled';
const STATE_DELIVERED = 'delivered';
const STATE_REVIEWED = 'reviewed';
const STATE_REVIEWED_BY_CUSTOMER = 'reviewed-by-customer';
const STATE_REVIEWED_BY_PROVIDER = 'reviewed-by-provider';
const STATE_ACCEPTED_REFUNDABLE = 'accepted-refundable';

// update booking
// before pick-up
const STATE_AWAIT_UPDATE_BOOKING_BEFORE_PICK_UP = 'await-update-booking-before-pick-up';
const STATE_ACCEPTED_UPDATE_BOOKING_BEFORE_PICK_UP = 'accepted-update-booking-before-pick-up';
const STATE_AUTO_ACCEPTED_UPDATE_BOOKING_BEFORE_PICK_UP = 'auto-accepted-update-booking-before-pick-up';
const STATE_CANCELLED_UPDATE_BOOKING_BEFORE_PICK_UP = 'cancelled-update-booking-before-pick-up';
const STATE_EXPIRED_UPDATE_BOOKING_BEFORE_PICK_UP = 'expired-update-booking-before-pick-up';
// update booking
// before drop-off
const STATE_AWAIT_UPDATE_BOOKING_BEFORE_DROP_OFF = 'await-update-booking-before-drop-off';
const STATE_ACCEPTED_UPDATE_BOOKING_BEFORE_DROP_OFF = 'accepted-update-booking-before-drop-off';
const STATE_AUTO_ACCEPTED_UPDATE_BOOKING_BEFORE_DROP_OFF = 'auto-accepted-update-booking-before-drop-off';
const STATE_CANCELLED_UPDATE_BOOKING_BEFORE_DROP_OFF = 'cancelled-update-booking-before-drop-off';
const STATE_EXPIRED_UPDATE_BOOKING_BEFORE_DROP_OFF = 'expired-update-booking-before-drop-off';


const STATE_ADMIN_ACCESS_FOR_SHU = 'admin-access-for-shu';
const STATE_ACCEPTED_NON_REFUNDABLE = 'accepted-non-refundable';
const STATE_ACCEPTED_AFTER_ONE_HOUR = 'accepted-after-one-hour';
const STATE_PICK_UP_REQUESTED_NON_REFUNDABLE = 'pick-up-requested-non-refundable';
const STATE_CANCELED_WITH_PENALTY = 'cancelled-with-penalty';
const STATE_PICK_UP_CONFIRMED = 'pick-up-confirmed';
const STATE_DROP_OFF_REQUESTED = 'drop-off-requested';
const STATE_ADMIN_REVIEW_NEEDED = 'admin-review-needed';
const STATE_WAITING_FOR_REFUND = 'waiting-for-refund';
const STATE_PICK_UP_REQUESTED_REFUNDABLE = 'state/pick-up-requested-refundable';

const stateDescription = {
  id: 'one-for-all',
  initial: STATE_INITIAL,

  // States
  states: {
    [STATE_INITIAL]: {
      on: {
        [TRANSITION_ENQUIRE]: STATE_ENQUIRY,
        [TRANSITION_REQUEST]: STATE_PREAUTHORIZED,
        [TRANSITION_REQUEST_PAYMENT_NORMAL_COMMERCIAL]: STATE_PENDING_PAYMENT,
        [TRANSITION_REQUEST_PAYMENT_NORMAL_PRIVATE]: STATE_PENDING_PAYMENT,
        [TRANSITION_REQUEST_PAYMENT_YOUNG_COMMERCIAL]: STATE_PENDING_PAYMENT,
        [TRANSITION_REQUEST_PAYMENT_YOUNG_PRIVATE]: STATE_PENDING_PAYMENT,
        [TRANSITION_REQUEST_PAYMENT]: STATE_PENDING_PAYMENT,
      },
    },
    [STATE_PENDING_PAYMENT]: {
      on: {
        [TRANSITION_EXPIRE_PAYMENT]: STATE_PAYMENT_EXPIRED,
        [TRANSITION_CONFIRM_PAYMENT]: STATE_PREAUTHORIZED,
        [TRANSITION_CONFIRM_PAYMENT_INSTANT]: STATE_ACCEPTED_REFUNDABLE,
        [TRANSITION_DEPOSIT_FAIL]: STATE_PAYMENT_EXPIRED,
        [TRANSITION_CONFIRM_PAYMENT_UNVERIFIED]: STATE_UNVERIFIED_BOOKED,
        [TRANSITION_CONFIRM_PAYMENT_INSTANT_UNVERIFIED]: STATE_UNVERIFIED_BOOKED_INSTANT
      },
    },

    [STATE_PAYMENT_EXPIRED]: {},
    [STATE_PREAUTHORIZED]: {
      on: {
        [TRANSITION_DECLINE]: STATE_DECLINED,
        [TRANSITION_EXPIRE]: STATE_DECLINED,
        [TRANSITION_WITHDRAW]: STATE_DECLINED,
        [TRANSITION_ADMIN_WITHDRAW]: STATE_DECLINED,
        [TRANSITION_ACCEPT]: STATE_ACCEPTED_REFUNDABLE,
        [TRANSITION_ACCEPT_BY_ADMIN]: STATE_ACCEPTED_REFUNDABLE
      },
    },
    [STATE_UNVERIFIED_BOOKED]: {
      on: {
        [TRANSITION_UNVERIFIED_DECLINED_PENDING_STATE]: STATE_DECLINED,
        [TRANSITION_UNVERIFIED_EXPIRE]: STATE_DECLINED,
        [TRANSITION_UNVERIFIED_WITHDRAW]: STATE_DECLINED,
        [TRANSITION_ACCEPTED_PENDING_STATE]: STATE_PREAUTHORIZED,
        [TRANSITION_UNVERIFIED_VERIFICATION_SENT]: STATE_UNVERIFIED_VERIFICATION_SENT,
        [TRANSITION_UNVERIFIED_VERIFICATION_SENT_ADMIN]: STATE_UNVERIFIED_VERIFICATION_SENT,
      }
    },
    [STATE_UNVERIFIED_BOOKED_INSTANT]: {
      on: {
        [TRANSITION_UNVERIFIED_EXPIRE_INSTANT]: STATE_DECLINED,
        [TRANSITION_UNVERIFIED_WITHDRAW_INSTANT]: STATE_DECLINED,
        [TRANSITION_UNVERIFIED_ACCEPTED_PENDING_STATE_INSTANT]: STATE_ACCEPTED_REFUNDABLE,
        [TRANSITION_UNVERIFIED_VERIFICATION_SENT_INSTANT]: STATE_UNVERIFIED_VERIFICATION_SENT_INSTANT,
        [TRANSITION_UNVERIFIED_VERIFICATION_SENT_INSTANT_ADMIN]: STATE_UNVERIFIED_VERIFICATION_SENT_INSTANT,
        [TRANSITION_UNVERIFIED_DECLINED_PENDING_STATE_INSTANT]: STATE_DECLINED
      }
    },

    [STATE_UNVERIFIED_VERIFICATION_SENT]: {
      on: {
        [TRANSITION_UNVERIFIED_VERIFICATION_ACCEPTED]: STATE_PREAUTHORIZED,
        [TRANSITION_UNVERIFIED_VERIFICATION_DECLINE]: STATE_DECLINED,
        [TRANSITION_UNVERIFIED_VERIFICATION_EXPIRE]: STATE_DECLINED
      }
    },
    [STATE_UNVERIFIED_VERIFICATION_SENT_INSTANT]: {
      on: {
        [TRANSITION_UNVERIFIED_VERIFICATION_INSTANT_DECLINE]: STATE_DECLINED,
        [TRANSITION_UNVERIFIED_VERIFICATION_INSTANT_EXPIRE]: STATE_DECLINED,
        [TRANSITION_UNVERIFIED_VERIFICATION_INSTANT_ACCEPTED]: STATE_ACCEPTED_REFUNDABLE
      }
    },

    [STATE_DECLINED]: {},
    [STATE_ACCEPTED_REFUNDABLE]: {
      on: {
        [TRANSITION_PROVIDER_CANCEL_REFUNDABLE]: STATE_CANCELED,
        [TRANSITION_REQUEST_PICK_UP_DLGO_AFTER_ACCEPTED]: STATE_PICK_UP_CONFIRMED,
        [TRANSITION_CUSTOMER_CANCEL_REFUNDABLE]: STATE_WAITING_FOR_REFUND,
        [TRANSITION_MARK_ACCEPTED_AFTER_ONE_HOUR]: STATE_ACCEPTED_AFTER_ONE_HOUR,
        [TRANSITION_ADMIN_CANCEL_AFTER_ACCEPTED_REFUNDABLE]: STATE_ADMIN_REVIEW_NEEDED,
        [TRANSITION_REQUEST_PICK_UP_AFTER_ACCEPTED]: STATE_PICK_UP_REQUESTED_NON_REFUNDABLE,
        [TRANSITION_UPDATE_BOOKING_BEFORE_PICK_UP_REFUNDABLE]: STATE_AWAIT_UPDATE_BOOKING_BEFORE_PICK_UP,
        [TRANSITION_ADMIN_CANCEL_UPDATE_BOOKING_AFTER_ACCEPTED_REFUNDABLE]: STATE_CANCELLED_UPDATE_BOOKING_BEFORE_PICK_UP
      },
    },
    [STATE_ACCEPTED_AFTER_ONE_HOUR]: {
      on: {
        [TRANSITION_PROVIDER_CANCEL_AFTER_ACCEPTED_ONE_HOUR]: STATE_CANCELED,
        [TRANSITION_CUSTOMER_CANCEL_AFTER_ACCEPTED_ONE_HOUR]: STATE_ADMIN_REVIEW_NEEDED,
        [TRANSITION_MARK_ACCEPTED_NON_REFUNDABLE]: STATE_ACCEPTED_NON_REFUNDABLE,
        [TRANSITION_ADMIN_CANCEL_AFTER_ACCEPTED_ONE_HOUR]: STATE_ADMIN_REVIEW_NEEDED,
        [TRANSITION_UPDATE_BOOKING_BEFORE_PICK_UP_ONE_HOUR]: STATE_AWAIT_UPDATE_BOOKING_BEFORE_PICK_UP,
      },
    },
    [STATE_ACCEPTED_NON_REFUNDABLE]: {
      on: {
        [TRANSITION_CUSTOMER_CANCEL_NON_REFUNDABLE]: STATE_CANCELED,
        [TRANSITION_REQUEST_PICK_UP_DLGO_NON_REFUNDABLE]: STATE_PICK_UP_CONFIRMED,
        [TRANSITION_REQUEST_PICK_UP_NON_REFUNDABLE]: STATE_PICK_UP_REQUESTED_NON_REFUNDABLE,
        [TRANSITION_PROVIDER_CANCEL_NON_REFUNDABLE]: STATE_CANCELED,
        [TRANSITION_EXPIRE_ACCEPTED_NON_REFUNDABLE]: STATE_DELIVERED,
        [TRANSITION_ADMIN_CANCEL_AFTER_ACCEPTED_NON_REFUNDABLE]: STATE_ADMIN_REVIEW_NEEDED,
        [TRANSITION_UPDATE_BOOKING_BEFORE_PICK_UP_NON_REFUNDABLE]: STATE_AWAIT_UPDATE_BOOKING_BEFORE_PICK_UP,
      },
    },
    [STATE_CANCELED]: {},
    [STATE_PICK_UP_REQUESTED_NON_REFUNDABLE]: {
      on: {
        [TRANSITION_CONFIRM_PICK_UP_NON_REFUNDABLE]: STATE_PICK_UP_CONFIRMED,
        [TRANSITION_ADMIN_CONFIRM_PICK_UP_NON_REFUNDABLE]: STATE_PICK_UP_CONFIRMED,
        [TRANSITION_CUSTOMER_CANCEL_PICK_UP_REQUESTED_NON_REFUNDABLE]: STATE_CANCELED,
        [TRANSITION_EXPIRE_CONFIRM_PICK_UP]: STATE_DELIVERED,
        [TRANSITION_ADMIN_CANCEL_PICK_UP_REQUESTED_NON_REFUNDABLE]: STATE_ADMIN_REVIEW_NEEDED,
      },
    },
    [STATE_ADMIN_ACCESS_FOR_SHU]: {
      on: {
        [ADMIN_ACCESS_AFTER_ACCEPTED_REFUNDABLE]: STATE_ADMIN_ACCESS_FOR_SHU,
        [ADMIN_ACCESS_AFTER_ACCEPTED_AFTER_ONE_HOUR]: STATE_ADMIN_ACCESS_FOR_SHU,
        [ADMIN_ACCESS_AFTER_ACCEPTED_AFTER_NON_REFUNDABLE]: STATE_ADMIN_ACCESS_FOR_SHU,
        [ADMIN_ACCESS_AFTER_ACCEPTED_AFTER_PICK_UP_REQUESTED_NON_REFUNDABLE]: STATE_ADMIN_ACCESS_FOR_SHU,
        [TRANSITION_EXPIRE_ADMIN_ACCESS_FOR_SHU]: STATE_DELIVERED,
      },
    },
    [STATE_PICK_UP_CONFIRMED]: {
      on: {
        [TRANSITION_REQUEST_DROP_OFF]: STATE_DROP_OFF_REQUESTED,
        [TRANSITION_REQUEST_DROP_OFF_DLGO]: STATE_DELIVERED,
        [TRANSITION_DROPOFF_REQUESTED_AFTER_ADMIN_ACCESS_FOR_SHU]: STATE_DROP_OFF_REQUESTED,
        [TRANSITION_EXPIRE_REQUEST_DROP_OFF]: STATE_DELIVERED,
        [TRANSITION_ADMIN_CANCEL_AFTER_PICK_UP_CONFIRMED]: STATE_ADMIN_REVIEW_NEEDED,
        [TRANSITION_UPDATE_BOOKING_BEFORE_DROP_OFF]: STATE_AWAIT_UPDATE_BOOKING_BEFORE_DROP_OFF,
        [TRANSITION_ADMIN_CANCEL_UPDATE_BOOKING_BEFORE_DROP_OFF_AFTER_ACCEPTED]: STATE_CANCELLED_UPDATE_BOOKING_BEFORE_DROP_OFF
      },
    },
    [STATE_DROP_OFF_REQUESTED]: {
      on: {
        [TRANSITION_DISPUTE]: STATE_DELIVERED,
        [TRANSITION_CONFIRM_DROP_OFF]: STATE_DELIVERED,
        [TRANSITION_ADMIN_CONFIRM_DROP_OFF]: STATE_DELIVERED,
        [TRANSITION_AUTO_COMPLETE]: STATE_DELIVERED,
      },
    },
    [STATE_AWAIT_UPDATE_BOOKING_BEFORE_PICK_UP]: {
      on: {
        [TRANSITION_ACCEPT_UPDATE_BOOKING_BEFORE_PICK_UP]: STATE_ACCEPTED_UPDATE_BOOKING_BEFORE_PICK_UP,
        [TRANSITION_AUTO_ACCEPT_UPDATE_BOOKING_BEFORE_PICK_UP]: STATE_AUTO_ACCEPTED_UPDATE_BOOKING_BEFORE_PICK_UP ,
        [TRANSITION_CANCEL_UPDATE_BOOKING_BEFORE_PICK_UP]: STATE_CANCELLED_UPDATE_BOOKING_BEFORE_PICK_UP,
        [TRANSITION_EXPIRE_UPDATE_BOOKING_BEFORE_PICK_UP]: STATE_EXPIRED_UPDATE_BOOKING_BEFORE_PICK_UP,
        [TRANSITION_ADMIN_ACCEPT_UPDATE_BOOKING_AFTER_AWAIT_UPDATE_BOOKING_BEFORE_PICK_UP]: STATE_ACCEPTED_UPDATE_BOOKING_BEFORE_PICK_UP,
        [TRANSITION_ADMIN_CANCEL_UPDATE_BOOKING_AFTER_AWAIT_UPDATE_BOOKING_BEFORE_PICK_UP]: STATE_CANCELLED_UPDATE_BOOKING_BEFORE_PICK_UP,
        [TRANSITION_PROVIDER_CANCEL_AFTER_UPDATE_BOOKING]: STATE_CANCELED,
        [TRANSITION_CUSTOMER_CANCEL_AFTER_UPDATE_BOOKING]: STATE_WAITING_FOR_REFUND,
        [TRANSITION_ADMIN_CANCEL_AFTER_UPDATE_BOOKING]: STATE_ADMIN_REVIEW_NEEDED,
      },
    },

    [STATE_ACCEPTED_UPDATE_BOOKING_BEFORE_PICK_UP]: {
      on: {
        [TRANSITION_RETURN_BACK_AFTER_ACCEPT_UPDATE_BOOKING_BEFORE_PICK_UP]: STATE_ACCEPTED_REFUNDABLE,

      },
    },

    [STATE_AUTO_ACCEPTED_UPDATE_BOOKING_BEFORE_PICK_UP]: {
      on: {
        [TRANSITION_RETURN_BACK_AFTER_AUTO_ACCEPT_UPDATE_BOOKING_BEFORE_PICK_UP]: STATE_ACCEPTED_REFUNDABLE,

      },
    },

    [STATE_CANCELLED_UPDATE_BOOKING_BEFORE_PICK_UP]: {
      on: {
        [TRANSITION_RETURN_BACK_AFTER_CANCEL_UPDATE_BOOKING_BEFORE_PICK_UP]: STATE_ACCEPTED_NON_REFUNDABLE,

      },
    },

    [STATE_EXPIRED_UPDATE_BOOKING_BEFORE_PICK_UP]: {
      on: {
        [TRANSITION_RETURN_BACK_AFTER_EXPIRE_UPDATE_BOOKING_BEFORE_PICK_UP]: STATE_ACCEPTED_NON_REFUNDABLE,

      },
    },

    [STATE_AWAIT_UPDATE_BOOKING_BEFORE_DROP_OFF]: {
      on: {
        [TRANSITION_ACCEPT_UPDATE_BOOKING_BEFORE_DROP_OFF]: STATE_ACCEPTED_UPDATE_BOOKING_BEFORE_DROP_OFF,
        [TRANSITION_AUTO_ACCEPT_UPDATE_BOOKING_BEFORE_DROP_OFF]: STATE_AUTO_ACCEPTED_UPDATE_BOOKING_BEFORE_DROP_OFF,
        [TRANSITION_CANCEL_UPDATE_BOOKING_BEFORE_DROP_OFF]: STATE_CANCELLED_UPDATE_BOOKING_BEFORE_DROP_OFF,
        [TRANSITION_EXPIRE_UPDATE_BOOKING_BEFORE_DROP_OFF]: STATE_EXPIRED_UPDATE_BOOKING_BEFORE_DROP_OFF,
        [TRANSITION_ADMIN_ACCEPT_UPDATE_BOOKING_BEFORE_DROP_OFF]: STATE_ACCEPTED_UPDATE_BOOKING_BEFORE_DROP_OFF,
        [TRANSITION_ADMIN_CANCEL_UPDATE_BOOKING_BEFORE_DROP_OFF]: STATE_CANCELLED_UPDATE_BOOKING_BEFORE_DROP_OFF,
      },
    },

    [STATE_ACCEPTED_UPDATE_BOOKING_BEFORE_DROP_OFF]: {
      on: {
        [TRANSITION_RETURN_BACK_AFTER_ACCEPT_UPDATE_BOOKING_BEFORE_DROP_OFF]: STATE_PICK_UP_CONFIRMED,

      },
    },

    [STATE_AUTO_ACCEPTED_UPDATE_BOOKING_BEFORE_DROP_OFF]: {
      on: {
        [TRANSITION_RETURN_BACK_AFTER_AUTO_ACCEPT_UPDATE_BOOKING_BEFORE_DROP_OFF]: STATE_PICK_UP_CONFIRMED,

      },
    },

    [STATE_CANCELLED_UPDATE_BOOKING_BEFORE_DROP_OFF]: {
      on: {
        [TRANSITION_RETURN_BACK_AFTER_CANCEL_UPDATE_BOOKING_BEFORE_DROP_OFF]: STATE_PICK_UP_CONFIRMED,

      },
    },

    [STATE_EXPIRED_UPDATE_BOOKING_BEFORE_DROP_OFF]: {
      on: {
        [TRANSITION_RETURN_BACK_AFTER_EXPIRE_UPDATE_BOOKING_BEFORE_DROP_OFF]: STATE_PICK_UP_CONFIRMED,

      },
    },


    [STATE_DELIVERED]: {
      on: {
        [TRANSITION_EXPIRE_REVIEW_PERIOD]: STATE_REVIEWED,
        [TRANSITION_REVIEW_1_BY_CUSTOMER]: STATE_REVIEWED_BY_CUSTOMER,
        [TRANSITION_REVIEW_1_BY_PROVIDER]: STATE_REVIEWED_BY_PROVIDER,
      },
    },
    [STATE_ADMIN_REVIEW_NEEDED]: {
      on: {
        [TRANSITION_ADMIN_CANCEL_REFUND]: STATE_WAITING_FOR_REFUND,
        [TRANSITION_ADMIN_CANCEL_NON_REFUND]: STATE_CANCELED,
      },
    },
    [STATE_WAITING_FOR_REFUND]: {
      on: {
        [TRANSITION_ADMIN_REFUND]: STATE_CANCELED,
      },
    },
    [STATE_REVIEWED_BY_CUSTOMER]: {
      on: {
        [TRANSITION_REVIEW_2_BY_PROVIDER]: STATE_REVIEWED,
        [TRANSITION_EXPIRE_PROVIDER_REVIEW_PERIOD]: STATE_REVIEWED,
      },
    },
    [STATE_REVIEWED_BY_PROVIDER]: {
      on: {
        [TRANSITION_REVIEW_2_BY_CUSTOMER]: STATE_REVIEWED,
        [TRANSITION_EXPIRE_CUSTOMER_REVIEW_PERIOD]: STATE_REVIEWED,
      },
    },
    [STATE_REVIEWED]: { type: 'final' },
  },
};

// Note: currently we assume that state description doesn't contain nested states.
const statesFromStateDescription = description => description.states || {};

// Get all the transitions from states object in an array
const getTransitions = states => {
  const stateNames = Object.keys(states);

  const transitionsReducer = (transitionArray, name) => {
    const stateTransitions = states[name] && states[name].on;
    const transitionKeys = stateTransitions ? Object.keys(stateTransitions) : [];
    return [
      ...transitionArray,
      ...transitionKeys.map(key => ({ key, value: stateTransitions[key] })),
    ];
  };

  return stateNames.reduce(transitionsReducer, []);
};

// This is a list of all the transitions that this app should be able to handle.
export const TRANSITIONS = getTransitions(statesFromStateDescription(stateDescription)).map(
  t => t.key
);

// This function returns a function that has given stateDesc in scope chain.
const getTransitionsToStateFn = stateDesc => state =>
  getTransitions(statesFromStateDescription(stateDesc))
    .filter(t => t.value === state)
    .map(t => t.key);

// Get all the transitions that lead to specified state.
const getTransitionsToState = getTransitionsToStateFn(stateDescription);

const preauthorizedTransitions = getTransitionsToState(STATE_PREAUTHORIZED);
const updateBookingBeforePickupTransitions = getTransitionsToState(STATE_AWAIT_UPDATE_BOOKING_BEFORE_PICK_UP);
const updateBookingBeforeDropOffTransitions = getTransitionsToState(STATE_AWAIT_UPDATE_BOOKING_BEFORE_DROP_OFF);

// This is needed to fetch transactions that need response from provider.
// I.e. transactions which provider needs to accept or decline
export const transitionsToRequested = [...preauthorizedTransitions, ...updateBookingBeforePickupTransitions, updateBookingBeforeDropOffTransitions ];

/**
 * Helper functions to figure out if transaction is in a specific state.
 * State is based on lastTransition given by transaction object and state description.
 */

const txLastTransition = tx => ensureTransaction(tx).attributes.lastTransition;

export const txIsEnquired = tx =>
  getTransitionsToState(STATE_ENQUIRY).includes(txLastTransition(tx));

export const txIsPaymentPending = tx =>
  getTransitionsToState(STATE_PENDING_PAYMENT).includes(txLastTransition(tx));

export const txIsPaymentExpired = tx =>
  getTransitionsToState(STATE_PAYMENT_EXPIRED).includes(txLastTransition(tx));

// Note: state name used in Marketplace API docs (and here) is actually preauthorized
// However, word "requested" is used in many places so that we decided to keep it.
export const txIsRequested = tx =>
  getTransitionsToState(STATE_PREAUTHORIZED).includes(txLastTransition(tx));

export const txIsUnverifiedRequested = tx =>
  getTransitionsToState(STATE_UNVERIFIED_BOOKED).includes(txLastTransition(tx));


export const txInVerificationProcess = tx => {
  return (txIsUnverifiedRequested(tx) ||
  txIsUnverifiedInstantRequested(tx) ||
  txIsUnverifiedSubmittedInstant(tx) ||
  txIsUnverifiedSubmitted(tx));
}


export const txIsUnverifiedInstantRequested = tx =>
  getTransitionsToState(STATE_UNVERIFIED_BOOKED_INSTANT).includes(txLastTransition(tx));

export const txIsUnverifiedSubmittedInstant = tx =>
  getTransitionsToState(STATE_UNVERIFIED_VERIFICATION_SENT_INSTANT).includes(txLastTransition(tx));

export const txIsUnverifiedSubmitted = tx =>
  getTransitionsToState(STATE_UNVERIFIED_VERIFICATION_SENT).includes(txLastTransition(tx));


export const txIsAccepted = tx =>
  getTransitionsToState(STATE_ACCEPTED_REFUNDABLE).includes(txLastTransition(tx)) ||
  getTransitionsToState(STATE_ACCEPTED_NON_REFUNDABLE).includes(txLastTransition(tx)) ||
  getTransitionsToState(STATE_ACCEPTED_AFTER_ONE_HOUR).includes(txLastTransition(tx));


export const txIsAcceptedRefundable = tx => getTransitionsToState(STATE_ACCEPTED_REFUNDABLE).includes(txLastTransition(tx))
export const txIsAcceptedNonRefundable = tx => getTransitionsToState(STATE_ACCEPTED_NON_REFUNDABLE).includes(txLastTransition(tx));
export const txIsAcceptedAfterOneHour = tx => getTransitionsToState(STATE_ACCEPTED_AFTER_ONE_HOUR).includes(txLastTransition(tx));

export const txDontShowPrivateInfo = tx =>
  getTransitionsToState(STATE_UNVERIFIED_BOOKED).includes(txLastTransition(tx)) ||
  getTransitionsToState(STATE_UNVERIFIED_BOOKED_INSTANT).includes(txLastTransition(tx)) ||
  getTransitionsToState(STATE_UNVERIFIED_VERIFICATION_SENT).includes(txLastTransition(tx)) ||
  getTransitionsToState(STATE_UNVERIFIED_VERIFICATION_SENT_INSTANT).includes(txLastTransition(tx)) ||
  getTransitionsToState(STATE_ENQUIRY).includes(txLastTransition(tx)) ||
  getTransitionsToState(STATE_PENDING_PAYMENT).includes(txLastTransition(tx)) ||
  getTransitionsToState(STATE_PREAUTHORIZED).includes(txLastTransition(tx)) ||
  getTransitionsToState(STATE_PAYMENT_EXPIRED).includes(txLastTransition(tx)) ||
  getTransitionsToState(STATE_DECLINED).includes(txLastTransition(tx)) ||
  getTransitionsToState(STATE_CANCELED).includes(txLastTransition(tx)) ||
  getTransitionsToState(STATE_REVIEWED).includes(txLastTransition(tx)) ||
  getTransitionsToState(STATE_REVIEWED_BY_PROVIDER).includes(txLastTransition(tx)) ||
  getTransitionsToState(STATE_REVIEWED_BY_CUSTOMER).includes(txLastTransition(tx)) ||
  getTransitionsToState(STATE_WAITING_FOR_REFUND).includes(txLastTransition(tx)) ||
  getTransitionsToState(STATE_ADMIN_REVIEW_NEEDED).includes(txLastTransition(tx))||
  getTransitionsToState(STATE_DELIVERED).includes(txLastTransition(tx));


export const txIsBeingPickedUp = tx => {
  const transition = txLastTransition(tx);
  return (
    transition === TRANSITION_REQUEST_PICK_UP_NON_REFUNDABLE ||
    transition === TRANSITION_REQUEST_PICK_UP_AFTER_ACCEPTED
  );
};

export const txIsReturnedBackBeforePickUp = tx => {
  const transition = txLastTransition(tx);
  return (
    transition === TRANSITION_RETURN_BACK_AFTER_ACCEPT_UPDATE_BOOKING_BEFORE_PICK_UP ||
    transition === TRANSITION_RETURN_BACK_AFTER_EXPIRE_UPDATE_BOOKING_BEFORE_PICK_UP ||
    transition === TRANSITION_RETURN_BACK_AFTER_CANCEL_UPDATE_BOOKING_BEFORE_PICK_UP ||
    transition === TRANSITION_RETURN_BACK_AFTER_AUTO_ACCEPT_UPDATE_BOOKING_BEFORE_PICK_UP
  );
};

export const txIsPickedUp = tx => {
  const transition = txLastTransition(tx);
  return transition === TRANSITION_CONFIRM_PICK_UP_NON_REFUNDABLE ||
    transition === TRANSITION_REQUEST_PICK_UP_DLGO_AFTER_ACCEPTED ||
    transition === TRANSITION_REQUEST_PICK_UP_DLGO_NON_REFUNDABLE ||
    transition === TRANSITION_ADMIN_CONFIRM_PICK_UP_NON_REFUNDABLE;

};

export const txWasPickedUp = tx => {
  const transitions = ensureTransaction(tx).attributes.transitions;

  const isPickedUpTx = transitions.find(item => {
    return [
      TRANSITION_CONFIRM_PICK_UP_NON_REFUNDABLE,
      TRANSITION_REQUEST_PICK_UP_DLGO_AFTER_ACCEPTED,
      TRANSITION_REQUEST_PICK_UP_DLGO_NON_REFUNDABLE,
      TRANSITION_ADMIN_CONFIRM_PICK_UP_NON_REFUNDABLE,
      ADMIN_ACCESS_AFTER_ACCEPTED_REFUNDABLE,
      ADMIN_ACCESS_AFTER_ACCEPTED_AFTER_ONE_HOUR,
      ADMIN_ACCESS_AFTER_ACCEPTED_AFTER_NON_REFUNDABLE,
      ADMIN_ACCESS_AFTER_ACCEPTED_AFTER_PICK_UP_REQUESTED_NON_REFUNDABLE,
    ].includes(item.transition)
  })

  return !!isPickedUpTx;
}

export const txAfterCancelUpdateTrip = tx => {
  const transition = txLastTransition(tx);
  return transition === TRANSITION_RETURN_BACK_AFTER_CANCEL_UPDATE_BOOKING_BEFORE_PICK_UP;
}

export const txIsShu = tx => {
  const transition = txLastTransition(tx);
  return (
    transition === ADMIN_ACCESS_AFTER_ACCEPTED_REFUNDABLE ||
    transition === ADMIN_ACCESS_AFTER_ACCEPTED_AFTER_ONE_HOUR ||
    transition === ADMIN_ACCESS_AFTER_ACCEPTED_AFTER_NON_REFUNDABLE ||
    transition === ADMIN_ACCESS_AFTER_ACCEPTED_AFTER_PICK_UP_REQUESTED_NON_REFUNDABLE
  );
};

export const txIsAcceptedUnverified = tx => {
  const transition = txLastTransition(tx);
  return (
    transition === TRANSITION_UNVERIFIED_VERIFICATION_ACCEPTED ||
    transition === TRANSITION_UNVERIFIED_ACCEPTED_PENDING_STATE_INSTANT ||
    transition === TRANSITION_UNVERIFIED_VERIFICATION_INSTANT_ACCEPTED
  );
}
export const txIsBeingDropOff = tx => {
  const transition = txLastTransition(tx);
  return (transition === TRANSITION_REQUEST_DROP_OFF) ||
    transition === TRANSITION_DROPOFF_REQUESTED_AFTER_ADMIN_ACCESS_FOR_SHU
    // transition === ADMIN_ACCESS_AFTER_ACCEPTED_REFUNDABLE  ||
    // transition === ADMIN_ACCESS_AFTER_ACCEPTED_AFTER_ONE_HOUR ||
    // transition === ADMIN_ACCESS_AFTER_ACCEPTED_AFTER_NON_REFUNDABLE ||
    // transition === ADMIN_ACCESS_AFTER_ACCEPTED_AFTER_PICK_UP_REQUESTED_NON_REFUNDABLE;
};

export const txIsDroppedOff = tx => {
  const transitions = tx.attributes.transitions;
  return !!transitions.find(item =>
    [
      TRANSITION_REQUEST_DROP_OFF,
      TRANSITION_DROPOFF_REQUESTED_AFTER_ADMIN_ACCESS_FOR_SHU,
      // ADMIN_ACCESS_AFTER_ACCEPTED_REFUNDABLE,
      // ADMIN_ACCESS_AFTER_ACCEPTED_AFTER_ONE_HOUR,
      // ADMIN_ACCESS_AFTER_ACCEPTED_AFTER_NON_REFUNDABLE,
      // ADMIN_ACCESS_AFTER_ACCEPTED_AFTER_PICK_UP_REQUESTED_NON_REFUNDABLE,
      TRANSITION_EXPIRE_UPDATE_BOOKING_BEFORE_DROP_OFF,
    ].includes(item.transition)
  );
};

export const txIsCancelledByProviderWithPenalty = tx =>
  txLastTransition(tx) === TRANSITION_PROVIDER_CANCEL_NON_REFUNDABLE;

export const txIsDeclined = tx =>
  getTransitionsToState(STATE_DECLINED).includes(txLastTransition(tx));

export const txIsCanceled = tx => {
  const lastTransition = txLastTransition(tx);
  return [
    ...getTransitionsToState(STATE_WAITING_FOR_REFUND),
    ...getTransitionsToState(STATE_ADMIN_REVIEW_NEEDED),
    ...getTransitionsToState(STATE_CANCELED),
  ].includes(lastTransition);
};

export const txIsCanceledByCustomer = tx => {
  return [
    TRANSITION_CUSTOMER_CANCEL_PICK_UP_REQUESTED_NON_REFUNDABLE,
    TRANSITION_CUSTOMER_CANCEL_NON_REFUNDABLE,
    TRANSITION_CUSTOMER_CANCEL_REFUNDABLE,
    TRANSITION_CUSTOMER_CANCEL_AFTER_ACCEPTED_ONE_HOUR,
  ].reduce((prev, transition) => {
    return prev || hasPassedTransition(transition, tx);
  }, false);
};
export const txIsCanceledRefundableByCustomer = tx => {
  return [
    TRANSITION_CUSTOMER_CANCEL_REFUNDABLE,
    TRANSITION_CUSTOMER_CANCEL_AFTER_ACCEPTED_ONE_HOUR,
  ].reduce((prev, transition) => {
    return prev || hasPassedTransition(transition, tx);
  }, false);
};

// update booking
// before pick-up
export const txIsAwaitUpdateBookingBeforePickUp = tx =>
  getTransitionsToState(STATE_AWAIT_UPDATE_BOOKING_BEFORE_PICK_UP).includes(txLastTransition(tx));

export const txIsAcceptedUpdateBookingBeforePickUp = tx =>
  getTransitionsToState(STATE_ACCEPTED_UPDATE_BOOKING_BEFORE_PICK_UP).includes(txLastTransition(tx));

export const txIsAutoAcceptedUpdateBookingBeforePickUp = tx =>
  getTransitionsToState(STATE_AUTO_ACCEPTED_UPDATE_BOOKING_BEFORE_PICK_UP).includes(txLastTransition(tx));

export const txIsCancelledUpdateBookingBeforePickUp = tx =>
  getTransitionsToState(STATE_CANCELLED_UPDATE_BOOKING_BEFORE_PICK_UP).includes(txLastTransition(tx));

export const txIsExpiredUpdateBookingBeforePickUp = tx =>
  getTransitionsToState(STATE_EXPIRED_UPDATE_BOOKING_BEFORE_PICK_UP).includes(txLastTransition(tx));

// update booking
// before drop-off

export const txIsAwaitUpdateBookingBeforeDropOff = tx =>
  getTransitionsToState(STATE_AWAIT_UPDATE_BOOKING_BEFORE_DROP_OFF).includes(txLastTransition(tx));

export const txIsAcceptedUpdateBookingBeforeDropOff = tx =>
  getTransitionsToState(STATE_PICK_UP_CONFIRMED).includes(txLastTransition(tx)) ||
  getTransitionsToState(STATE_ACCEPTED_UPDATE_BOOKING_BEFORE_DROP_OFF).includes(txLastTransition(tx));

export const txIsAutoAcceptedUpdateBookingBeforeDropOff = tx =>
  getTransitionsToState(STATE_AUTO_ACCEPTED_UPDATE_BOOKING_BEFORE_DROP_OFF).includes(txLastTransition(tx));

export const txIsCancelledUpdateBookingBeforeDropOff = tx =>
  getTransitionsToState(STATE_PICK_UP_CONFIRMED).includes(txLastTransition(tx)) ||
  getTransitionsToState(STATE_CANCELLED_UPDATE_BOOKING_BEFORE_DROP_OFF).includes(txLastTransition(tx));

export const txIsAdminCancelledUpdateBookingBeforeDropOffAfterAccepted = tx =>
  getTransitionsToState(STATE_CANCELLED_UPDATE_BOOKING_BEFORE_DROP_OFF).includes(txLastTransition(tx));

export const txIsExpiredUpdateBookingBeforeDropOff = tx =>
  getTransitionsToState(STATE_EXPIRED_UPDATE_BOOKING_BEFORE_DROP_OFF).includes(txLastTransition(tx));

export const txIsDelivered = tx =>
  getTransitionsToState(STATE_DELIVERED).includes(txLastTransition(tx));

export const txIsWithdrawn = tx => {
  const transition = txLastTransition(tx);
  return transition === TRANSITION_WITHDRAW ||  transition === TRANSITION_UNVERIFIED_WITHDRAW || transition === TRANSITION_UNVERIFIED_WITHDRAW_INSTANT;
};

export const txIsWithdrawnByAdmin = tx => {
  const transition = txLastTransition(tx);
  return transition === TRANSITION_ADMIN_WITHDRAW;
};

export const txIsExpired = tx => {
  const transition = txLastTransition(tx);
  return transition === TRANSITION_EXPIRE || transition === TRANSITION_UNVERIFIED_EXPIRE || transition ===TRANSITION_UNVERIFIED_EXPIRE_INSTANT;
};

export const txIsCanceledByHost = tx => {
  return [
    TRANSITION_PROVIDER_CANCEL_AFTER_ACCEPTED_ONE_HOUR,
    TRANSITION_PROVIDER_CANCEL_NON_REFUNDABLE,
    TRANSITION_PROVIDER_CANCEL_REFUNDABLE,
  ].reduce((prev, transition) => prev || hasPassedTransition(transition, tx), false);
};

export const txIsCompleted = tx => {
  const transition = txLastTransition(tx);
  return getTransitionsToState(STATE_DELIVERED).includes(transition);
};

export const txIWaitingRequestPickup = tx => {
  const transition = txLastTransition(tx);
  return [
    TRANSITION_MARK_ACCEPTED_NON_REFUNDABLE,
    TRANSITION_CONFIRM_PAYMENT_INSTANT,
    TRANSITION_ACCEPT,
    TRANSITION_ACCEPT_BY_ADMIN
  ].includes(transition);
};

export const txIWaitingConfirmPickup = tx => {
  const transition = txLastTransition(tx);
  return transition === TRANSITION_REQUEST_PICK_UP_NON_REFUNDABLE;
};

export const txIWaitingRequestDropoff = tx => {
  const transition = txLastTransition(tx);
  return transition === TRANSITION_CONFIRM_PICK_UP_NON_REFUNDABLE || transition === TRANSITION_ADMIN_CONFIRM_PICK_UP_NON_REFUNDABLE;
};

export const txIBetweenPickupDropoff = tx => {
  const transition = txLastTransition(tx);
  return (
    transition === TRANSITION_CONFIRM_PICK_UP_NON_REFUNDABLE ||
    transition === TRANSITION_ADMIN_CONFIRM_PICK_UP_NON_REFUNDABLE ||
    transition === TRANSITION_REQUEST_PICK_UP_NON_REFUNDABLE
  );
};

export const txIsAfterConfirmPickUp = tx => {
  const transition = txLastTransition(tx);
  return (
    transition === TRANSITION_CONFIRM_PICK_UP_NON_REFUNDABLE ||
    transition === TRANSITION_ADMIN_CONFIRM_PICK_UP_NON_REFUNDABLE ||
    txIsBeingDropOff(tx) ||
    txIsCompleted(tx) ||
    txIsReviewed(tx)
  );
};

export const txIsAfterConfirmDropoff = tx => {
  return txIsCompleted(tx) || txIsReviewed(tx);
};

// Refer to the card: https://trello.com/c/BFMoEFjd/775-when-the-due-process-is-not-followed-eg-lets-say-pick-up-and-drop-off-process-not-followed-then-the-status-being-shown-is-incorr

export const txIsPickupPending = tx => {
  return txLastTransition(tx) === TRANSITION_EXPIRE_CONFIRM_PICK_UP;
};

export const txIsDropoffPending = tx => {
  return txLastTransition(tx) === TRANSITION_EXPIRE_REQUEST_DROP_OFF;
};

export const txIsDispute = tx => {
  return txLastTransition(tx) === TRANSITION_DISPUTE;
};

const firstReviewTransitions = [
  ...getTransitionsToState(STATE_REVIEWED_BY_CUSTOMER),
  ...getTransitionsToState(STATE_REVIEWED_BY_PROVIDER),
];

//NOT SURE WOULD NEEDED
export const txHasFirstReview = tx => firstReviewTransitions.includes(txLastTransition(tx));

export const txHasFirstReviewedByProvider = tx =>
  TRANSITION_REVIEW_1_BY_PROVIDER === txLastTransition(tx);

export const txHasFirstReviewedByCustomer = tx =>
  TRANSITION_REVIEW_1_BY_CUSTOMER === txLastTransition(tx);

//END

// export const txIsInFirstReview = tx => firstReviewTransitions.includes(txLastTransition(tx));

export const txIsInFirstReviewBy = (tx, isCustomer) =>
  isCustomer
    ? getTransitionsToState(STATE_REVIEWED_BY_CUSTOMER).includes(txLastTransition(tx))
    : getTransitionsToState(STATE_REVIEWED_BY_PROVIDER).includes(txLastTransition(tx));

export const txIsReviewed = tx =>
  getTransitionsToState(STATE_REVIEWED).includes(txLastTransition(tx));

export const txIsWaitingForRefund = tx =>
  getTransitionsToState(STATE_WAITING_FOR_REFUND).includes(txLastTransition(tx));

export const txIsRefunded = tx => {
  const transition = txLastTransition(tx);
  return transition === TRANSITION_ADMIN_REFUND;
};

export const txIsNonRefunded = tx => {
  return hasPassedTransition(TRANSITION_ADMIN_CANCEL_NON_REFUND, tx);
};

/**
 * Helper functions to figure out if transaction has passed a given state.
 * This is based on transitions history given by transaction object.
 */

const txTransitions = tx => ensureTransaction(tx).attributes.transitions || [];
const hasPassedTransition = (transitionName, tx) =>
  !!txTransitions(tx).find(t => t.transition === transitionName);

const hasPassedStateFn = state => tx =>
  getTransitionsToState(state).filter(t => hasPassedTransition(t, tx)).length > 0;

// export const txHasBeenAccepted = hasPassedStateFn(STATE_ACCEPTED);
export const txHasBeenAccepted = tx => {
  return (
    hasPassedTransition(TRANSITION_ACCEPT, tx) ||
    hasPassedTransition(TRANSITION_CONFIRM_PAYMENT_INSTANT, tx) ||
    hasPassedTransition(TRANSITION_ACCEPT_BY_ADMIN, tx)
  );
};

export const txHasBeenDelivered = hasPassedStateFn(STATE_DELIVERED);

/**
 * Other transaction related utility functions
 */

export const transitionIsReviewed = transition =>
  getTransitionsToState(STATE_REVIEWED).includes(transition);

export const transitionIsFirstReviewedBy = (transition, isCustomer) =>
  isCustomer
    ? getTransitionsToState(STATE_REVIEWED_BY_CUSTOMER).includes(transition)
    : getTransitionsToState(STATE_REVIEWED_BY_PROVIDER).includes(transition);

export const getReview1Transition = isCustomer =>
  isCustomer ? TRANSITION_REVIEW_1_BY_CUSTOMER : TRANSITION_REVIEW_1_BY_PROVIDER;

export const getReview2Transition = isCustomer =>
  isCustomer ? TRANSITION_REVIEW_2_BY_CUSTOMER : TRANSITION_REVIEW_2_BY_PROVIDER;

// Check if a transition is the kind that should be rendered
// when showing transition history (e.g. ActivityFeed)
// The first transition and most of the expiration transitions made by system are not relevant
export const isRelevantPastTransition = transition => {
  return [
    TRANSITION_WITHDRAW,
    TRANSITION_UNVERIFIED_WITHDRAW,
    TRANSITION_ACCEPT,
    TRANSITION_ACCEPT_BY_ADMIN,
    TRANSITION_UNVERIFIED_EXPIRE_INSTANT,
    TRANSITION_UNVERIFIED_WITHDRAW_INSTANT,
    TRANSITION_CONFIRM_PAYMENT_UNVERIFIED,
    TRANSITION_UNVERIFIED_ACCEPTED_PENDING_STATE_INSTANT,
    TRANSITION_UNVERIFIED_VERIFICATION_SENT_INSTANT,
    TRANSITION_UNVERIFIED_VERIFICATION_SENT_INSTANT_ADMIN,
    TRANSITION_UNVERIFIED_VERIFICATION_SENT,
    TRANSITION_UNVERIFIED_VERIFICATION_SENT_ADMIN,
    TRANSITION_CONFIRM_PAYMENT_INSTANT_UNVERIFIED,
    TRANSITION_UNVERIFIED_DECLINED_PENDING_STATE_INSTANT,
    TRANSITION_UNVERIFIED_VERIFICATION_EXPIRE,
    TRANSITION_UNVERIFIED_VERIFICATION_DECLINE,
    TRANSITION_UNVERIFIED_VERIFICATION_ACCEPTED,
    TRANSITION_UNVERIFIED_VERIFICATION_INSTANT_DECLINE,
    TRANSITION_UNVERIFIED_VERIFICATION_INSTANT_EXPIRE,
    TRANSITION_UNVERIFIED_VERIFICATION_INSTANT_ACCEPTED,
    TRANSITION_CUSTOMER_CANCEL_AFTER_ACCEPTED_ONE_HOUR,
    TRANSITION_PROVIDER_CANCEL_AFTER_ACCEPTED_ONE_HOUR,
    TRANSITION_CUSTOMER_CANCEL_REFUNDABLE,
    TRANSITION_CUSTOMER_CANCEL_NON_REFUNDABLE,
    TRANSITION_PROVIDER_CANCEL_REFUNDABLE,
    TRANSITION_PROVIDER_CANCEL_NON_REFUNDABLE,
    TRANSITION_CUSTOMER_CANCEL_PICK_UP_REQUESTED_NON_REFUNDABLE,
    TRANSITION_REQUEST_PICK_UP_AFTER_ACCEPTED,
    TRANSITION_REQUEST_PICK_UP_NON_REFUNDABLE,
    TRANSITION_CONFIRM_PICK_UP_NON_REFUNDABLE,
    TRANSITION_REQUEST_DROP_OFF,
    TRANSITION_DROPOFF_REQUESTED_AFTER_ADMIN_ACCESS_FOR_SHU,
    TRANSITION_DISPUTE,
    TRANSITION_CONFIRM_DROP_OFF,
    TRANSITION_CANCEL,
    TRANSITION_DECLINE,
    TRANSITION_EXPIRE,
    TRANSITION_REQUEST,
    TRANSITION_REVIEW_1_BY_CUSTOMER,
    TRANSITION_REVIEW_1_BY_PROVIDER,
    TRANSITION_REVIEW_2_BY_CUSTOMER,
    TRANSITION_REVIEW_2_BY_PROVIDER,
    TRANSITION_EXPIRE_ACCEPTED_NON_REFUNDABLE,
    TRANSITION_EXPIRE_REQUEST_DROP_OFF,
    TRANSITION_EXPIRE_CONFIRM_PICK_UP,
    TRANSITION_AUTO_COMPLETE,
    TRANSITION_ADMIN_WITHDRAW,
    TRANSITION_ADMIN_CANCEL_AFTER_ACCEPTED_REFUNDABLE,
    TRANSITION_ADMIN_CANCEL_AFTER_ACCEPTED_NON_REFUNDABLE,
    TRANSITION_ADMIN_CANCEL_PICK_UP_REQUESTED_NON_REFUNDABLE,
    TRANSITION_ADMIN_CANCEL_AFTER_PICK_UP_CONFIRMED,
    TRANSITION_FAKE_WAITING_FOR_REFUND,
    TRANSITION_UPDATE_BOOKING_BEFORE_PICK_UP_REFUNDABLE,
    TRANSITION_UPDATE_BOOKING_BEFORE_PICK_UP_ONE_HOUR,
    TRANSITION_UPDATE_BOOKING_BEFORE_PICK_UP_NON_REFUNDABLE,
    TRANSITION_ACCEPT_UPDATE_BOOKING_BEFORE_PICK_UP,
    TRANSITION_AUTO_ACCEPT_UPDATE_BOOKING_BEFORE_PICK_UP,
    TRANSITION_CANCEL_UPDATE_BOOKING_BEFORE_PICK_UP,
    TRANSITION_EXPIRE_UPDATE_BOOKING_BEFORE_PICK_UP,
    TRANSITION_ADMIN_CANCEL_UPDATE_BOOKING_AFTER_ACCEPTED_REFUNDABLE,
    TRANSITION_ADMIN_CANCEL_UPDATE_BOOKING_AFTER_ACCEPTED_NON_REFUNDABLE,
    TRANSITION_ADMIN_ACCEPT_UPDATE_BOOKING_AFTER_AWAIT_UPDATE_BOOKING_BEFORE_PICK_UP,
    TRANSITION_ADMIN_CANCEL_UPDATE_BOOKING_AFTER_AWAIT_UPDATE_BOOKING_BEFORE_PICK_UP,
    TRANSITION_UPDATE_BOOKING_BEFORE_DROP_OFF,
    TRANSITION_ACCEPT_UPDATE_BOOKING_BEFORE_DROP_OFF,
    TRANSITION_AUTO_ACCEPT_UPDATE_BOOKING_BEFORE_DROP_OFF,
    TRANSITION_CANCEL_UPDATE_BOOKING_BEFORE_DROP_OFF,
    TRANSITION_EXPIRE_UPDATE_BOOKING_BEFORE_DROP_OFF,
    TRANSITION_ADMIN_ACCEPT_UPDATE_BOOKING_BEFORE_DROP_OFF,
    TRANSITION_ADMIN_CANCEL_UPDATE_BOOKING_BEFORE_DROP_OFF,
    TRANSITION_ADMIN_CANCEL_UPDATE_BOOKING_BEFORE_DROP_OFF_AFTER_ACCEPTED,
  ].includes(transition);
};

export const isCustomerReview = transition => {
  return [TRANSITION_REVIEW_1_BY_CUSTOMER, TRANSITION_REVIEW_2_BY_CUSTOMER].includes(transition);
};

export const isProviderReview = transition => {
  return [TRANSITION_REVIEW_1_BY_PROVIDER, TRANSITION_REVIEW_2_BY_PROVIDER].includes(transition);
};

export const getUserTxRole = (currentUserId, transaction) => {
  const tx = ensureTransaction(transaction);
  const customer = tx.customer;
  if (currentUserId && currentUserId.uuid && tx.id && customer.id) {
    // user can be either customer or provider
    return currentUserId.uuid === customer.id.uuid
      ? TX_TRANSITION_ACTOR_CUSTOMER
      : TX_TRANSITION_ACTOR_PROVIDER;
  } else {
    throw new Error(`Parameters for "userIsCustomer" function were wrong.
      currentUserId: ${currentUserId}, transaction: ${transaction}`);
  }
};

export const txRoleIsProvider = userRole => userRole === TX_TRANSITION_ACTOR_PROVIDER;
export const txRoleIsCustomer = userRole => userRole === TX_TRANSITION_ACTOR_CUSTOMER;

export const calculateBookingDays = (start, end) => {
  if (!start || !end) {
    return 0;
  }
  const finalStart = start instanceof Date ? start : new Date(start);
  const finalEnd = end instanceof Date ? end : new Date(end);
  return Math.ceil((finalEnd.getTime() - finalStart.getTime()) / (24 * 60 * 60 * 1000));
};

export const txIsNormalCommercial = tx =>
  hasPassedTransition(TRANSITION_REQUEST_PAYMENT_NORMAL_COMMERCIAL, tx);
export const txIsNormalPrivate = tx =>
  hasPassedTransition(TRANSITION_REQUEST_PAYMENT_NORMAL_PRIVATE, tx);
export const txIsYoungCommercial = tx =>
  hasPassedTransition(TRANSITION_REQUEST_PAYMENT_YOUNG_COMMERCIAL, tx);
export const txIsYoungPrivate = tx =>
  hasPassedTransition(TRANSITION_REQUEST_PAYMENT_YOUNG_PRIVATE, tx);

export const txIsYoungProcess = tx => txIsYoungCommercial(tx) || txIsYoungPrivate(tx);

export const txGetPromoLineItem = tx => {
  //todo: a user can only have 1 kind of promo for a booking
  return tx.attributes.lineItems.find(l => {
    return [
      LINE_ITEM_CUSTOMER_PROMO,
      LINE_ITEM_MASTERCARD_PROMO,
      LINE_ITEM_NEW_CAR_DISCOUNT,
    ].includes(l.code);
  });
};

export const txIsCustomerCancelNeedRefund = tx => {
  return (
    hasPassedTransition(TRANSITION_CUSTOMER_CANCEL_REFUNDABLE, tx) ||
    hasPassedTransition(TRANSITION_CUSTOMER_CANCEL_AFTER_ACCEPTED_ONE_HOUR, tx)
  );
};

export const isNeedCustomerPickUpAction = tx => {
  const transition = txLastTransition(tx);
  return (
    transition === TRANSITION_ACCEPT ||
    transition === TRANSITION_ACCEPT_BY_ADMIN ||
    transition === TRANSITION_MARK_ACCEPTED_NON_REFUNDABLE ||
    transition === TRANSITION_CONFIRM_PAYMENT_INSTANT ||
    transition === TRANSITION_RETURN_BACK_AFTER_CANCEL_UPDATE_BOOKING_BEFORE_PICK_UP ||
    transition === TRANSITION_RETURN_BACK_AFTER_EXPIRE_UPDATE_BOOKING_BEFORE_PICK_UP ||
    transition === TRANSITION_RETURN_BACK_AFTER_ACCEPT_UPDATE_BOOKING_BEFORE_PICK_UP
  );
}

export const isNeedProviderPickUpAction = tx => {
  const transition = txLastTransition(tx);
  return transition === TRANSITION_REQUEST_PICK_UP_AFTER_ACCEPTED || transition === TRANSITION_REQUEST_PICK_UP_NON_REFUNDABLE;
}

export const isNeedCustomerDropOffAction = tx => {
  const transition = txLastTransition(tx);
  return transition === TRANSITION_CONFIRM_PICK_UP_NON_REFUNDABLE ||
      transition === TRANSITION_ADMIN_CONFIRM_PICK_UP_NON_REFUNDABLE ||
      transition === TRANSITION_REQUEST_PICK_UP_DLGO_NON_REFUNDABLE ||
      transition === TRANSITION_REQUEST_PICK_UP_DLGO_AFTER_ACCEPTED ||
      transition === TRANSITION_EXPIRE_UPDATE_BOOKING_BEFORE_DROP_OFF ||
      transition === TRANSITION_RETURN_BACK_AFTER_ACCEPT_UPDATE_BOOKING_BEFORE_DROP_OFF ||
      transition === TRANSITION_RETURN_BACK_AFTER_CANCEL_UPDATE_BOOKING_BEFORE_DROP_OFF ||
      transition === TRANSITION_RETURN_BACK_AFTER_EXPIRE_UPDATE_BOOKING_BEFORE_DROP_OFF ||
      transition === ADMIN_ACCESS_AFTER_ACCEPTED_REFUNDABLE ||
      transition === ADMIN_ACCESS_AFTER_ACCEPTED_AFTER_ONE_HOUR ||
      transition === ADMIN_ACCESS_AFTER_ACCEPTED_AFTER_NON_REFUNDABLE ||
      transition === ADMIN_ACCESS_AFTER_ACCEPTED_AFTER_PICK_UP_REQUESTED_NON_REFUNDABLE;
};

export const isNeedProviderDropOffAction = tx => {
  const transition = txLastTransition(tx);
  return transition === TRANSITION_REQUEST_DROP_OFF;
};

export const isNeedGiveReview = tx => {
  const transition = txLastTransition(tx);
  return (
    transition === TRANSITION_CONFIRM_DROP_OFF ||
    transition === TRANSITION_ADMIN_CONFIRM_DROP_OFF ||
    transition === TRANSITION_DISPUTE ||
    transition === TRANSITION_AUTO_COMPLETE
  );
}
