import React, { useState, useRef, useEffect } from 'react';
import ProgressBar from '../ProgressBar/ProgressBar';
import css from '../index.css';
import { useDispatch } from 'react-redux';
import {OdometerReadingContentText} from './constant'
import UploadSection from '../UploadSection'
import { nextPickStep, nextDropStep, enableProgressBar} from '../ProgressBar/ProgressBar.duck';
import IconSpinner from '../../IconSpinner/IconSpinner';
import AlertBox from '../../AlertBox/AlertBox';

const OdometerReadingComponent = ({ 
  isPickUp, 
  currentUser, 
  intl, 
  isCustomer,
  onNoteChange, 
  setOdometerPhotos,
  odometerPhotos, 
  setActivePhotoSection,
  pickUpOdometer,
  dropOffOdometer,
  isDrivelahGo
}) => {

  const [isUploaded, setIsUploaded] = useState(false);
  const [loading, setLoading] = useState(false);
  const [odometerReading, setOdometerReading] = useState('');
  const [value, setValue] = useState(null);


  const dispatch = useDispatch();
  const uploadSectionRef = useRef(null);

  useEffect(() => {
    console.log("odometerPhotos", odometerPhotos)
    if (odometerPhotos && odometerPhotos.length) {
      const updatedPhotos = odometerPhotos.map(photo => ({
        ...photo,
        note: odometerReading,
      }));
      setOdometerPhotos(updatedPhotos);
    }
  }, [odometerReading]);

  const handleChange = (e) => {
    const newValue = e.target.value;
    const numberValue = parseInt(newValue, 10);
    if (isNaN(numberValue) || numberValue <= 999999) {
      if (!newValue) {
        setOdometerReading(null);
        setValue(null);
      } else {
        setValue(newValue);
        setOdometerReading(newValue);
      }
    }
  };


  const handleAddMorePhotos = () => {
    if (uploadSectionRef.current) {
      uploadSectionRef.current.handleClickUpload();
    }
  };

  const handleNextStep = () => {
    switch (currentTextContent.buttonText) {
      case 'Upload Photos':
        handleAddMorePhotos();
        break;
      case 'Continue to next step':
        if(isPickUp) {
            dispatch(nextPickStep());
        } else {
            dispatch(nextDropStep());
            dispatch(enableProgressBar(false));

        }
        break;
      default:
        console.warn('Unhandled button text:', currentTextContent.buttonText);
    }
  };

  const currentTextContent = isUploaded ? OdometerReadingContentText.odometerReadingPhotos : OdometerReadingContentText.odometerReadingView;
  const disable = !isUploaded && loading  || isUploaded && ((value ?? 0) <= pickUpOdometer || value === null || isNaN(value));

  return (
    <>
      <div className={css.stepContainer}>
        <div className={css.wrapper}>
          <div className={css.contentWrapper}>
            <h1>{currentTextContent.title}</h1>
            <p>{currentTextContent.message}</p>
          </div>
          { !isUploaded && !loading &&
            <div className={css.imgWrapper}>
                <img
                src={currentTextContent.img}
                alt={currentTextContent.title}
              />
            </div>
          }
          <UploadSection
            ref={uploadSectionRef}
            intl={intl}
            currentUser={currentUser}
            isPickUp={isPickUp}
            isCustomer={isCustomer}
            onNoteChange={onNoteChange}
            setIsUploaded={setIsUploaded}
            setLoading={setLoading}
            setPhotosData ={setOdometerPhotos}
            type={'odometerPhotos'}
            note={ odometerReading }
            setActivePhotoSection = {setActivePhotoSection}
            id={ isDrivelahGo && isPickUp  ? 3 : 2}

          />
        </div> {isUploaded &&
          <div className={css.odometerReadingWrapper}>
            <h1>Enter the current odometer reading</h1>
            <p>Enter the current reading in the text field below. Ensure its the same as in the photo you took.</p>
            {/* <input placeholder='Enter the odometer reading'  type="number" value={odometerReading}
                  onChange={(e) => setOdometerReading(e.target.value)}/> */}
            <div className={css.readingInput}>
              <input 
                type="number" 
                max={999999} 
                min={!isPickUp ? pickUpOdometer : 0}
                id="reading" 
                value={value}
                onChange={handleChange} 
                name="reading" 
                required 
                placeholder=" "/>
              <label htmlFor="reading">Odometer reading</label>
            </div>
            {!isPickUp && value <= pickUpOdometer && (
              <AlertBox
                title="Input error"
                message={`Odometer reading must be greater than pickup odometer reading`}
                type="error"
              />
            )}
          </div>
        }

      </div>

      <div className={css.footerContainer}>
        <div className={`${css.footerWrapper} ${css.stepContainer}`}>
          <div className={css.footerProgressBar}>
            <ProgressBar isPickUp={isPickUp} />
          </div>
          <button
            className={css.footerButton}
            onClick={handleNextStep}
            disabled={disable}
          >
            {loading ? "Uploading..." : currentTextContent.buttonText}
            <div>
              {loading && (
                <div className={css.uploadLoading}>
                  <IconSpinner />
                </div>
              )}
            </div>
          </button>
        </div>
      </div>
    </>
  );
};

export default OdometerReadingComponent;
