import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { createResourceLocatorString, findRouteByRouteName } from '../../util/routes';
import { withViewport } from '../../util/contextHelpers';
import routeConfiguration from '../../routeConfiguration';
import { propTypes } from '../../util/types';
import { ensureListing, ensureTransaction, restoreTransaction } from '../../util/data';
import { dateFromAPIToLocalNoon } from '../../util/dates';
import { createSlug } from '../../util/urlHelpers';
import { txIsPaymentPending } from '../../util/transaction';
import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { isScrollingDisabled, manageDisableScrolling } from '../../ducks/UI.duck';
import { initializeCardPaymentData } from '../../ducks/stripe.duck.js';
import { estimateBreakdown, resetCode } from '../ListingPage/ListingPage.duck';
import {
  LayoutSingleColumn,
  LayoutWrapperFooter,
  LayoutWrapperMain,
  LayoutWrapperTopbar,
  NamedRedirect,
  Page,
  TripPanel,
} from '../../components';
import { TopbarContainer } from '../../containers';
import {
  EVENT_BOOK_REQUEST_ACCEPTED_GUEST,
  EVENT_BOOK_REQUEST_ACCEPTED_HOST,
  EVENT_BOOK_REQUEST_DECLINED_GUEST,
  EVENT_BOOK_REQUEST_DECLINED_HOST,
  PROVIDER_ACCEPT_BOOKING_BUTTON_NAME,
  PROVIDER_DECLINE_BOOKING_BUTTON_NAME,
} from '../../util/gtm/gtmConstants';
import { initiateEventFromTransaction } from '../../util/gtm/gtmHelpers';
import {
  $bookingTx,
  acceptSale,
  declineSale,
  fetchCancelUpdateBooking,
  fetchMoreMessages,
  fetchTimeSlots,
  handlePaymentFuel,
  loadData,
  requestToUpdateBooking,
  sendMessage,
  sendReview,
  setInitialValues,
  transit,
  getDistanceFromPickUp,
  uploadInteriorPhotoToMetadata,
} from './TransactionPage.duck';
import css from './TransactionPage.css';
import {
  acceptUpdateBooking,
  cancelAllUpdateBookingTxs,
  cancelUpdateBooking,
} from '../EditTripPage/EditTripPage.duck';
import {
  $bookingUpdateTxsByParentTxId,
  $cancelAllUpdateBookingTxsInProgress,
  $fetchLastUpdateBookingTxInProgress,
  $isAcceptUpdateBookingPending,
  $isCancelUpdateBookingPending,
  $lastBookingUpdateByTxId,
} from '../EditTripPage/EditTripPage.selectors';
import { $isLimitedUser } from '../../ducks/Auth.duck';
import {
  acceptUpdateBookingByAdmin,
  cancelUpdateBookingByAdmin,
} from '../../modules/admin-actions/update-booking/adminUpdateBooking.duck';
import {
  $isAcceptUpdateBookingByAdminPending,
  $isCancelUpdateBookingByAdminPending,
} from '../../modules/admin-actions/update-booking/adminUpdateBooking.selectors';
import { GenericError } from "../../components/Topbar/Topbar";

const PROVIDER = 'provider';
const CUSTOMER = 'customer';
const MAX_MOBILE_SCREEN_WIDTH = 768;
const MAX_TABLET_SCREEN_WIDTH = 1024;

// TransactionPage handles data loading for Sale and Order views to transaction pages in Inbox.
export const TransactionPageComponent = props => {
  const {
    fetchLastUpdateBookingTxInProgress,
    cancelAllUpdateBookingTxsInProgress,
    bookingUpdateTxs,
    lastBookingUpdate,
    acceptUpdateBookingInProgress,
    cancelUpdateBookingInProgress,
    currentUser,
    initialMessageFailedToTransaction,
    savePaymentMethodFailed,
    fetchMessagesError,
    fetchMessagesInProgress,
    totalMessagePages,
    oldestMessagePageFetched,
    fetchTransactionError,
    history,
    intl,
    messages,
    onManageDisableScrolling,
    onSendMessage,
    onSendReview,
    onShowMoreMessages,
    params,
    scrollingDisabled,
    sendMessageError,
    sendMessageInProgress,
    sendReviewError,
    sendReviewInProgress,
    transaction,
    transactionRole,
    acceptInProgress,
    acceptSaleError,
    declineInProgress,
    declineSaleError,
    onAcceptSale,
    onDeclineSale,
    timeSlots,
    fetchTimeSlotsError,
    useInitialValues,
    transitInProgress,
    transitError,
    onTransit,
    ownListing,
    processTransitions,
    callSetInitialValues,
    onInitializeCardPaymentData,
    location,
    onResetCode,
    onHandlePaymentFuel,
    viewport,
    onUploadInteriorPhotoToMetadata,
    uploadInteriorPhotoProgress,
    uploadInteriorSuccess,
    monthlyTimeSlots,
    onEstimateBreakdown,
    onFetchCancelUpdateBooking,
    estimatedTx,
    estimateError,
    estimateBreakdownInProgress,
    onFetchTimeSlots,
    customerUpdateBookingInProgress,
    transactionUpdateBooking,
    listingForMap,
    onAcceptUpdateBooking,
    onCancelUpdateBooking,
    onCancelAllUpdateBookings,
    isLimitedUser,
    onCancelUpdateBookingByAdmin,
    onAcceptUpdateBookingByAdmin,
    isCancelUpdateBookingByAdminPending,
    isAcceptUpdateBookingByAdminPending,
    acceptUpdateBookingError,
    getDistanceFromPickUp,
    distanceFromPickUp,
    userLocation
  } = props;


  const currentTransaction = restoreTransaction(ensureTransaction(transaction));
  const transactionWithoutRestore = ensureTransaction(transaction);
  const currentListing = ensureListing(currentTransaction.listing);
  const isProviderRole = transactionRole === PROVIDER;
  const isCustomerRole = transactionRole === CUSTOMER;

  const isMobileLayout = viewport.width < MAX_MOBILE_SCREEN_WIDTH;
  const isTabletLayout = viewport.width < MAX_TABLET_SCREEN_WIDTH;
  

  const redirectToCheckoutPageWithInitialValues = (initialValues, listing) => {
    const routes = routeConfiguration();
    // Customize checkout page state with current listing and selected bookingDates
    const { setInitialValues } = findRouteByRouteName('CheckoutPage', routes);
    callSetInitialValues(setInitialValues, initialValues);

    // Clear previous Stripe errors from store if there is any
    onInitializeCardPaymentData();

    // Redirect to CheckoutPage
    history.push(
      createResourceLocatorString(
        'CheckoutPage',
        routes,
        { id: currentListing.id.uuid, slug: createSlug(currentListing.attributes.title) },
        {}
      )
    );
  };

  // If payment is pending, redirect to CheckoutPage
  if (
    txIsPaymentPending(transactionWithoutRestore) &&
    isCustomerRole &&
    transactionWithoutRestore.attributes.lineItems
  ) {
    const currentBooking = ensureListing(transactionWithoutRestore.booking);

    const initialValues = {
      listing: currentListing,
      // Transaction with payment pending should be passed to CheckoutPage
      transaction: transactionWithoutRestore,
      // Original bookingData content is not available,
      // but it is already used since booking is created.
      // (E.g. quantity is used when booking is created.)
      bookingData: {},
      bookingDates: {
        bookingStart: dateFromAPIToLocalNoon(currentBooking.attributes.start),
        bookingEnd: dateFromAPIToLocalNoon(currentBooking.attributes.end),
      },
    };

    redirectToCheckoutPageWithInitialValues(initialValues, currentListing);
  }

  // Customer can create a booking, if the tx is in "enquiry" state.
  const handleSubmitBookingRequest = values => {
    const { bookingDates, ...bookingData } = values;

    const initialValues = {
      listing: currentListing,
      // enquired transaction should be passed to CheckoutPage
      transaction: currentTransaction,
      bookingData,
      bookingDates: {
        bookingStart: bookingDates.startDate,
        bookingEnd: bookingDates.endDate,
      },
      confirmPaymentError: null,
    };

    redirectToCheckoutPageWithInitialValues(initialValues, currentListing);
  };

  const deletedListingTitle = intl.formatMessage({
    id: 'TransactionPage.deletedListing',
  });
  const listingTitle = currentListing.attributes.deleted
    ? deletedListingTitle
    : currentListing.attributes.title;

  // Redirect users with someone else's direct link to their own inbox/sales or inbox/orders page.
  const isDataAvailable =
    currentUser &&
    currentTransaction.id &&
    currentTransaction.id.uuid === params.id &&
    currentTransaction.attributes.lineItems &&
    currentTransaction.customer &&
    currentTransaction.provider &&
    !fetchTransactionError;

  const isOwnSale =
    isDataAvailable &&
    isProviderRole &&
    currentUser.id.uuid === currentTransaction.provider.id.uuid;
  const isOwnOrder =
    isDataAvailable &&
    isCustomerRole &&
    currentUser.id.uuid === currentTransaction.customer.id.uuid;

  if (isDataAvailable && isProviderRole && !isOwnSale) {
    // eslint-disable-next-line no-console
    console.error('Tried to access a sale that was not owned by the current user');
    return <NamedRedirect name="InboxPage" params={{ tab: 'sales' }} />;
  } else if (isDataAvailable && isCustomerRole && !isOwnOrder) {
    // eslint-disable-next-line no-console
    console.error('Tried to access an order that was not owned by the current user');
    return <NamedRedirect name="InboxPage" params={{ tab: 'orders' }} />;
  }

  const detailsClassName = classNames(css.tabContent, css.tabContentVisible);

  const fetchErrorMessage = isCustomerRole
    ? 'TransactionPage.fetchOrderFailed'
    : 'TransactionPage.fetchSaleFailed';
  const loadingMessage = isCustomerRole
    ? 'TransactionPage.loadingOrderData'
    : 'TransactionPage.loadingSaleData';

  const loadingOrFailedFetching = fetchTransactionError ? (
    <p className={css.error}>
      <FormattedMessage id={`${fetchErrorMessage}`} />
    </p>
  ) : (
    <p className={css.loading}>
      <FormattedMessage id={`${loadingMessage}`} />
    </p>
  );

  const initialMessageFailed = !!(
    initialMessageFailedToTransaction &&
    currentTransaction.id &&
    initialMessageFailedToTransaction.uuid === currentTransaction.id.uuid
  );

  const acceptSaleGTM = () => {
    initiateEventFromTransaction({
      props,
      transaction,
      buttonId: PROVIDER_ACCEPT_BOOKING_BUTTON_NAME,
      buttonText: intl.formatMessage({ id: 'TransactionPanel.acceptButton' }),
      event: EVENT_BOOK_REQUEST_ACCEPTED_HOST,
      isMultipleButton: true,
      existTransaction: true,
    });
    initiateEventFromTransaction({
      props,
      transaction,
      buttonId: PROVIDER_ACCEPT_BOOKING_BUTTON_NAME,
      buttonText: intl.formatMessage({ id: 'TransactionPanel.acceptButton' }),
      event: EVENT_BOOK_REQUEST_ACCEPTED_GUEST,
      isMultipleButton: true,
      existTransaction: true,
      userDiffActionTaker: true,
    });
  };

  const declineSaleGTM = () => {
    initiateEventFromTransaction({
      props,
      transaction,
      buttonId: PROVIDER_DECLINE_BOOKING_BUTTON_NAME,
      buttonText: intl.formatMessage({ id: 'TransactionPanel.declineButton' }),
      event: EVENT_BOOK_REQUEST_DECLINED_HOST,
      isMultipleButton: true,
      existTransaction: true,
    });
    initiateEventFromTransaction({
      props,
      transaction,
      buttonId: PROVIDER_DECLINE_BOOKING_BUTTON_NAME,
      buttonText: intl.formatMessage({ id: 'TransactionPanel.declineButton' }),
      event: EVENT_BOOK_REQUEST_DECLINED_GUEST,
      isMultipleButton: true,
      existTransaction: true,
      userDiffActionTaker: true,
    });
  };

  // TransactionPanel is presentational component
  // that currently handles showing everything inside layout's main view area.
  const panel = isDataAvailable ? (
    // isCustomerRole ? (
    <TripPanel
      cancelAllUpdateBookingTxsInProgress={cancelAllUpdateBookingTxsInProgress}
      bookingUpdateTxs={bookingUpdateTxs}
      lastBookingUpdate={lastBookingUpdate}
      acceptUpdateBookingInProgress={acceptUpdateBookingInProgress}
      cancelUpdateBookingInProgress={cancelUpdateBookingInProgress}
      className={detailsClassName}
      currentUser={currentUser}
      transaction={currentTransaction}
      transactionUpdateBooking={transactionUpdateBooking}
      fetchMessagesInProgress={fetchMessagesInProgress}
      totalMessagePages={totalMessagePages}
      oldestMessagePageFetched={oldestMessagePageFetched}
      messages={messages}
      initialMessageFailed={initialMessageFailed}
      savePaymentMethodFailed={savePaymentMethodFailed}
      fetchMessagesError={fetchMessagesError}
      sendMessageInProgress={sendMessageInProgress}
      sendMessageError={sendMessageError}
      sendReviewInProgress={sendReviewInProgress}
      sendReviewError={sendReviewError}
      onManageDisableScrolling={onManageDisableScrolling}
      onShowMoreMessages={onShowMoreMessages}
      onSendMessage={onSendMessage}
      onSendReview={onSendReview}
      transactionRole={transactionRole}
      onAcceptSale={onAcceptSale}
      onDeclineSale={onDeclineSale}
      acceptInProgress={acceptInProgress}
      declineInProgress={declineInProgress}
      acceptSaleError={acceptSaleError}
      declineSaleError={declineSaleError}
      acceptSaleGTM={acceptSaleGTM}
      declineSaleGTM={declineSaleGTM}
      nextTransitions={processTransitions}
      onSubmitBookingRequest={handleSubmitBookingRequest}
      timeSlots={timeSlots}
      fetchTimeSlotsError={fetchTimeSlotsError}
      transitInProgress={transitInProgress}
      transitError={transitError}
      onTransit={onTransit}
      onUploadInteriorPhotoToMetadata={onUploadInteriorPhotoToMetadata}
      ownListing={ownListing}
      transactionWithoutRestore={transactionWithoutRestore}
      acceptButtonName={PROVIDER_ACCEPT_BOOKING_BUTTON_NAME}
      declineButtonName={PROVIDER_DECLINE_BOOKING_BUTTON_NAME}
      location={location}
      history={history}
      viewport={viewport}
      callSetInitialValues={callSetInitialValues}
      onInitializeCardPaymentData={onInitializeCardPaymentData}
      onResetCode={onResetCode}
      isMobileLayout={isMobileLayout}
      isTabletLayout={isTabletLayout}
      uploadInteriorPhotoProgress={uploadInteriorPhotoProgress}
      uploadInteriorSuccess={uploadInteriorSuccess}
      monthlyTimeSlots={monthlyTimeSlots}
      onHandlePaymentFuel={onHandlePaymentFuel}
      onEstimateBreakdown={onEstimateBreakdown}
      estimatedTx={estimatedTx}
      estimateError={estimateError}
      estimateBreakdownInProgress={estimateBreakdownInProgress}
      onFetchTimeSlots={onFetchTimeSlots}
      customerUpdateBookingInProgress={customerUpdateBookingInProgress}
      onFetchCancelUpdateBooking={onFetchCancelUpdateBooking}
      listingForMap={listingForMap}
      onAcceptUpdateBooking={onAcceptUpdateBooking}
      onCancelUpdateBooking={onCancelUpdateBooking}
      onCancelAllUpdateBookings={onCancelAllUpdateBookings}
      isLimitedUser={isLimitedUser}
      onCancelUpdateBookingByAdmin={onCancelUpdateBookingByAdmin}
      onAcceptUpdateBookingByAdmin={onAcceptUpdateBookingByAdmin}
      isCancelUpdateBookingByAdminPending={isCancelUpdateBookingByAdminPending}
      isAcceptUpdateBookingByAdminPending={isAcceptUpdateBookingByAdminPending}
      getDistanceFromPickUp = {getDistanceFromPickUp}
      distanceFromPickUp = {distanceFromPickUp}
      userLocation = {userLocation}
    />
  ) : (
    // ) : (
    //    <TransactionPanel
    //       className={detailsClassName}
    //       currentUser={currentUser}
    //       transaction={currentTransaction}
    //       fetchMessagesInProgress={fetchMessagesInProgress}
    //       totalMessagePages={totalMessagePages}
    //       oldestMessagePageFetched={oldestMessagePageFetched}
    //       messages={messages}
    //       initialMessageFailed={initialMessageFailed}
    //       savePaymentMethodFailed={savePaymentMethodFailed}
    //       fetchMessagesError={fetchMessagesError}
    //       sendMessageInProgress={sendMessageInProgress}
    //       sendMessageError={sendMessageError}
    //       sendReviewInProgress={sendReviewInProgress}
    //       sendReviewError={sendReviewError}
    //       onManageDisableScrolling={onManageDisableScrolling}
    //       onShowMoreMessages={onShowMoreMessages}
    //       onSendMessage={onSendMessage}
    //       onSendReview={onSendReview}
    //       transactionRole={transactionRole}
    //       onAcceptSale={onAcceptSale}
    //       onDeclineSale={onDeclineSale}
    //       acceptInProgress={acceptInProgress}
    //       declineInProgress={declineInProgress}
    //       acceptSaleError={acceptSaleError}
    //       declineSaleError={declineSaleError}
    //       acceptSaleGTM={acceptSaleGTM}
    //       declineSaleGTM={declineSaleGTM}
    //       nextTransitions={processTransitions}
    //       onSubmitBookingRequest={handleSubmitBookingRequest}
    //       timeSlots={timeSlots}
    //       monthlyTimeSlots={monthlyTimeSlots}
    //       fetchTimeSlotsError={fetchTimeSlotsError}
    //       transitInProgress={transitInProgress}
    //       transitError={transitError}
    //       onTransit={onTransit}
    //       ownListing={ownListing}
    //       transactionWithoutRestore={transactionWithoutRestore}
    //       acceptButtonName={PROVIDER_ACCEPT_BOOKING_BUTTON_NAME}
    //       declineButtonName={PROVIDER_DECLINE_BOOKING_BUTTON_NAME}
    //       location={location}
    //       history={history}
    //       callSetInitialValues={callSetInitialValues}
    //       onInitializeCardPaymentData={onInitializeCardPaymentData}
    //       onResetCode={onResetCode}
    //       onHandlePaymentFuel={onHandlePaymentFuel}
    //       onEstimateBreakdown={onEstimateBreakdown}
    //       onRequestToUpdateBooking={onRequestToUpdateBooking}
    //       estimatedTx={estimatedTx}
    //       estimateError={estimateError}
    //       estimateBreakdownInProgress={estimateBreakdownInProgress}
    //       onFetchTimeSlots={onFetchTimeSlots}
    //       customerUpdateBookingInProgress={customerUpdateBookingInProgress}
    //     />
    // )
    loadingOrFailedFetching
  );

  const acceptUpdateBookingErrorMessage = !!acceptUpdateBookingError
      ? <FormattedMessage id='TransactionPage.acceptUpdateBookingErrorMessage' />
      : null;

  return (
    <Page
      title={intl.formatMessage({ id: 'TransactionPage.title' }, { title: listingTitle })}
      scrollingDisabled={scrollingDisabled}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer />
        </LayoutWrapperTopbar>
        <LayoutWrapperMain>
          {acceptUpdateBookingError
            ? <GenericError show={!!acceptUpdateBookingError} error={acceptUpdateBookingErrorMessage} />
            : null
          }
          <div className={css.root}>{panel}</div>
        </LayoutWrapperMain>
        <LayoutWrapperFooter className={css.footer}>
          {/* <Footer /> */}
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </Page>
  );
};

TransactionPageComponent.defaultProps = {
  currentUser: null,
  fetchTransactionError: null,
  acceptSaleError: null,
  declineSaleError: null,
  transaction: null,
  fetchMessagesError: null,
  initialMessageFailedToTransaction: null,
  savePaymentMethodFailed: false,
  sendMessageError: null,
  timeSlots: null,
  monthlyTimeSlots: null,
  fetchTimeSlotsError: null,
};

const { bool, func, oneOf, shape, string, arrayOf, number, object } = PropTypes;

TransactionPageComponent.propTypes = {
  params: shape({ id: string }).isRequired,
  transactionRole: oneOf([PROVIDER, CUSTOMER]).isRequired,
  currentUser: propTypes.currentUser,
  fetchTransactionError: propTypes.error,
  acceptSaleError: propTypes.error,
  declineSaleError: propTypes.error,
  acceptInProgress: bool.isRequired,
  declineInProgress: bool.isRequired,
  onAcceptSale: func.isRequired,
  onDeclineSale: func.isRequired,
  scrollingDisabled: bool.isRequired,
  transaction: propTypes.transaction,
  fetchMessagesError: propTypes.error,
  totalMessagePages: number.isRequired,
  oldestMessagePageFetched: number.isRequired,
  messages: arrayOf(propTypes.message).isRequired,
  initialMessageFailedToTransaction: propTypes.uuid,
  savePaymentMethodFailed: bool,
  sendMessageInProgress: bool.isRequired,
  sendMessageError: propTypes.error,
  onShowMoreMessages: func.isRequired,
  onSendMessage: func.isRequired,
  timeSlots: arrayOf(propTypes.timeSlot),
  monthlyTimeSlots: object,
  // monthlyTimeSlots could be something like:
  // monthlyTimeSlots: {
  //   '2019-11': {
  //     timeSlots: [],
  //     fetchTimeSlotsInProgress: false,
  //     fetchTimeSlotsError: null,
  //   }
  // }
  fetchTimeSlotsError: propTypes.error,
  callSetInitialValues: func.isRequired,
  onInitializeCardPaymentData: func.isRequired,

  transitInProgress: bool.isRequired,
  transitError: propTypes.error,
  onTransit: func.isRequired,
  onUploadInteriorPhotoToMetadata: func.isRequired,
  onFetchCancelUpdateBooking: func.isRequired,

  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({
    search: string,
  }).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const {
    fetchTransactionError,
    acceptSaleError,
    declineSaleError,
    acceptInProgress,
    declineInProgress,
    fetchMessagesInProgress,
    fetchMessagesError,
    totalMessagePages,
    oldestMessagePageFetched,
    messages,
    initialMessageFailedToTransaction,
    savePaymentMethodFailed,
    sendMessageInProgress,
    sendMessageError,
    sendReviewInProgress,
    sendReviewError,
    monthlyTimeSlots,
    fetchTimeSlotsError,
    transitInProgress,
    transitError,
    processTransitions,
    nextLongTermTransaction,
    currentChildLongTermTransaction,
    childLongTermTransactions,
    customerUpdateBookingInProgress,
    uploadInteriorPhotoProgress,
    uploadInteriorSuccess,
    transactionUpdateBookingRef,
    listingForMap,
    distanceFromPickUp,
    userLocation
  } = state.TransactionPage;

  const { acceptUpdateBookingError } = state.EditTripPage;

  const transactionsUpdateBooking = getMarketplaceEntities(state, transactionUpdateBookingRef ? [transactionUpdateBookingRef] : []);
  const transactionUpdateBooking = transactionsUpdateBooking.length > 0 ? transactionsUpdateBooking[0] : null;

  if (nextLongTermTransaction && transactionsUpdateBooking) {
    transactionsUpdateBooking.nextTransaction = nextLongTermTransaction;
    transactionsUpdateBooking.childTransaction = childLongTermTransactions;
    transactionsUpdateBooking.currentChildTransaction = currentChildLongTermTransaction;
  }

  const { estimatedTx, estimateBreakdownInProgress, timeSlots, estimateError } = state.ListingPage;
  const { currentUser } = state.user;

  const transaction = $bookingTx(state);
  if (nextLongTermTransaction && transaction) {
    transaction.nextTransaction = nextLongTermTransaction;
    transaction.childTransaction = childLongTermTransactions;
    transaction.currentChildTransaction = currentChildLongTermTransaction;
  }

  let ownListing = null;
  if (transaction) {
    const ownListingRef = {
      id: transaction.listing.id,
      type: 'ownListing',
    };
    const ownListings = getMarketplaceEntities(state, [ownListingRef]);
    ownListing = ownListings.length > 0 ? ownListings[0] : null;
  }
  const bookingTxId = transaction ? transaction.id.uuid : null;
  return {
    currentUser,
    fetchTransactionError,
    acceptSaleError,
    declineSaleError,
    acceptInProgress,
    declineInProgress,
    scrollingDisabled: isScrollingDisabled(state),
    transaction,
    fetchMessagesInProgress,
    fetchMessagesError,
    totalMessagePages,
    oldestMessagePageFetched,
    messages,
    initialMessageFailedToTransaction,
    savePaymentMethodFailed,
    sendMessageInProgress,
    sendMessageError,
    sendReviewInProgress,
    sendReviewError,
    timeSlots,
    monthlyTimeSlots,
    fetchTimeSlotsError,
    transitInProgress,
    transitError,
    ownListing,
    processTransitions,
    uploadInteriorPhotoProgress,
    uploadInteriorSuccess,
    estimatedTx,
    estimateError,
    estimateBreakdownInProgress,
    customerUpdateBookingInProgress,
    transactionUpdateBooking,
    listingForMap,
    isLimitedUser: $isLimitedUser(state),
    acceptUpdateBookingInProgress: $isAcceptUpdateBookingPending(state),
    cancelUpdateBookingInProgress: $isCancelUpdateBookingPending(state),
    lastBookingUpdate: $lastBookingUpdateByTxId(state, bookingTxId),
    bookingUpdateTxs: $bookingUpdateTxsByParentTxId(state, bookingTxId),
    cancelAllUpdateBookingTxsInProgress: $cancelAllUpdateBookingTxsInProgress(state),
    fetchLastUpdateBookingTxInProgress: $fetchLastUpdateBookingTxInProgress(state),
    isCancelUpdateBookingByAdminPending: $isCancelUpdateBookingByAdminPending(state),
    isAcceptUpdateBookingByAdminPending: $isAcceptUpdateBookingByAdminPending(state),
    acceptUpdateBookingError,
    distanceFromPickUp, 
    userLocation
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onAcceptSale: (transactionId, userObj = null, process = null) => dispatch(acceptSale(transactionId, userObj, process)),
    onDeclineSale: (transactionId, process) => dispatch(declineSale(transactionId, process)),
    onShowMoreMessages: txId => dispatch(fetchMoreMessages(txId)),
    onSendMessage: (txId, message) => dispatch(sendMessage(txId, message)),
    onManageDisableScrolling: (componentId, disableScrolling) =>
      dispatch(manageDisableScrolling(componentId, disableScrolling)),
    onSendReview: (role, tx, reviewRating, reviewContent) =>
      dispatch(sendReview(role, tx, reviewRating, reviewContent)),
    useInitialValues: (setInitialValues, values) => dispatch(setInitialValues(values)),
    onFetchTimeSlots: (listingId, start, end, timeZone) =>
      dispatch(fetchTimeSlots(listingId, start, end, timeZone)),
    onEstimateBreakdown: params => dispatch(estimateBreakdown(params)),
    onRequestToUpdateBooking: params => dispatch(requestToUpdateBooking(params)),
    onFetchCancelUpdateBooking: params => dispatch(fetchCancelUpdateBooking(params)),
    onTransit: (
      transactionId,
      transition,
      cancelData = {},
      tripPhotos = null,
      certificate = null,
      isDrivelahGo = null,
      isStartCar = null,
      isLockCar = null,
      isUnlockCar = null,
      isLocateCar = null,
      isDlGoV3 = null,
      isStopCar = null,
      cleanlinessScore = null
    ) => dispatch(transit(transactionId, transition, cancelData, tripPhotos, certificate, isDrivelahGo, isStartCar, isLockCar, isUnlockCar, isLocateCar, isDlGoV3, isStopCar, cleanlinessScore)),
    callSetInitialValues: (setInitialValues, values) => dispatch(setInitialValues(values)),
    onInitializeCardPaymentData: () => dispatch(initializeCardPaymentData()),
    onResetCode: () => dispatch(resetCode()),
    onHandlePaymentFuel: (params) => dispatch(handlePaymentFuel(params)),
    onUploadInteriorPhotoToMetadata: (transactionId, tripPhotos = null,) => dispatch(uploadInteriorPhotoToMetadata(transactionId, tripPhotos)),
    onAcceptUpdateBooking: bookingTxId => dispatch(acceptUpdateBooking(bookingTxId)),
    onCancelUpdateBooking: bookingTxId => dispatch(cancelUpdateBooking(bookingTxId)),
    onCancelAllUpdateBookings: bookingTxId => dispatch(cancelAllUpdateBookingTxs(bookingTxId)),
    onCancelUpdateBookingByAdmin: tx => dispatch(cancelUpdateBookingByAdmin(tx)),
    onAcceptUpdateBookingByAdmin: tx => dispatch(acceptUpdateBookingByAdmin(tx)),
    getDistanceFromPickUp: listingLocation => dispatch(getDistanceFromPickUp(listingLocation))
  };
};

const TransactionPage = compose(
  withRouter,
  withViewport,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(TransactionPageComponent);

TransactionPage.loadData = params => {

  // const id = new UUID(params.id);
  return loadData(params);
};



TransactionPage.setInitialValues = setInitialValues;

export default TransactionPage;
